


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { EventBus, EVENT_TOGGLE_FORM_CONTACT } from '~/event-bus'
import { ILinkItem } from '~/types'

@Component({})
export default class ButtonContact extends Vue {
  @Prop() item!: ILinkItem

  onOpen() {
    EventBus.$emit(EVENT_TOGGLE_FORM_CONTACT, 1)
  }
}
