

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class InputCheckbox extends Vue {
  @Prop() label!: string
  @Prop() value!: boolean
  @Prop() error!: string

  get isChecked(): boolean {
    return this.value
  }

  set isChecked(value: boolean) {
    this.$emit('input', value)
  }
}
