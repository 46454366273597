


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'

@Component({})
export default class InputTextarea extends Vue {
  @Prop() label!: string
  @Prop() value!: string
  @Prop() error!: string

  onFocus() {
    const textarea = this.$refs.textarea as HTMLTextAreaElement

    if (textarea.value === '') {
      gsap.to(this.$refs.label as HTMLElement, {
        scale: 0.8,
        marginTop: '-8px',
      })
    }
  }

  onFocusOut() {
    const textarea = this.$refs.textarea as HTMLTextAreaElement

    if (textarea.value === '') {
      gsap.to(this.$refs.label as HTMLElement, { scale: 1, marginTop: '0' })
    }
  }
}
