

















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { directive } from 'vue-awesome-swiper'
import tailwindConfig from '../tailwind.config.js'
import { ISettings } from '~/types'

@Component({
  directives: {
    swiper: directive,
  },
})
export default class Gallery extends Vue {
  @Prop() indexOpen!: number

  @Getter settings!: ISettings

  $refs!: {
    projectSwiper: HTMLElement
    projectsSwiper: HTMLElement
    anchor: HTMLElement
  }

  @Watch('indexOpen')
  watchIndex() {
    this.handleClick(this.indexOpen)
  }

  iconLeftColor: string = tailwindConfig.theme.colors.gray[500]
  iconRightColor: string = tailwindConfig.theme.colors.gray[500]
  orange: string = tailwindConfig.theme.colors.orange[400]
  gray: string = tailwindConfig.theme.colors.gray[500]
  activeProjectIndex: number = 0
  activeProjectItemIndex: number = 0

  projectSwiperOptions: object = {
    slidesPerView: 1,
    navigation: {
      nextEl: '#project-swiper_next',
      prevEl: '#project-swiper_prev',
    },
  }

  projectsSwiperOptions: object = {
    breakpoints: {
      0: {
        slidesPerView: 1.2,
      },
      1024: {
        slidesPerView: 2,
      },
    },
    spaceBetween: 32,
    navigation: {
      nextEl: '#projects-swiper_next',
      prevEl: '#projects-swiper_prev',
    },
  }

  onSlideChange() {
    this.activeProjectItemIndex = (this as any).projectSwiper.activeIndex
  }

  get activeItem() {
    if (this.settings.gallery && this.settings.gallery[0]) {
      return this.settings.gallery[0].items[this.activeProjectIndex]
    }

    return null
  }

  handleClick(index: number) {
    this.activeProjectItemIndex = 0
    ;(this as any).projectSwiper.slideTo(0)
    this.activeProjectIndex = index
    this.$refs.anchor.scrollIntoView({ behavior: 'smooth' })
  }
}
