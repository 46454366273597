











import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import countriesEN from '../assets/json/countries.en.json'
import countriesDE from '../assets/json/countries.de.json'

export type IOption = {
  id: string
  label: string
}

@Component({})
export default class InputCountry extends Vue {
  @Prop() label!: string
  @Prop() error!: string

  @Getter locale!: string
  @Getter geoDetected!: string

  countries: IOption[] = []
  countriesTop: string[] = (process.env.COUNTRIES_TOP as any) || []
  countrySelectedId: string = ''
  languageLocaleMap: { [languageKey: string]: string[] } = process.env
    .COUNTRIES_MAP as any

  get countrySelectedName(): string {
    return (
      countriesEN.find((country) => country.id === this.countrySelectedId)
        ?.label || ''
    )
  }

  get options(): IOption[] {
    return [
      ...this.countriesTop.map(
        (id) =>
          this.countries.find(
            (c) => c.id.toLocaleUpperCase() === id.toLocaleUpperCase()
          ) || {
            id: '',
            label: '',
          }
      ),
      ...this.countries.filter((c) => !this.countriesTop.includes(c.id)),
    ]
  }

  created() {
    this.setCountries()

    this.countrySelectedId =
      this.options.find((o) => o.id.toLocaleUpperCase() === this.geoDetected)
        ?.id || this.options[0].id
    this.$emit('input', this.countrySelectedName)
  }

  setCountries() {
    let lang = ''

    // find lang depending on which website used
    Object.keys(this.languageLocaleMap).forEach((langKey) => {
      if (this.languageLocaleMap[langKey].includes(this.locale)) {
        lang = langKey
      }
    })

    if (lang === 'de') {
      this.countries = countriesDE
    } else {
      this.countries = countriesEN
    }
  }

  onInput() {
    this.$emit('input', this.countrySelectedName)
  }
}
