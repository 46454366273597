


























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { Richtext } from 'storyblok-js-client'
// @ts-ignore
import SplitText from 'assets/js/split-text.min.js'
import { EventBus, ANIMATION_SECTION_READY } from '../../../event-bus'
import { preloadImages, calcDrawImage } from '~/plugins/helper'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollTrigger)

@Component({})
export default class Scrollanimation extends Vue {
  @Prop() blok!: {
    title_first: Richtext
    title_second: Richtext
    title_third: Richtext
    text_first: string
    text_second: string
    text_third: string
    label_first: string
    label_second: string
  }

  isMobile: boolean = false
  triggerPosition: string = '20%'
  scrollLength: string = '+=525%'
  timeline: GSAPTimeline = gsap.timeline()

  $refs!: {
    canvasWrapper: HTMLElement
    canvasContainer: HTMLElement
    canvas: HTMLCanvasElement
    scrolltrigger: HTMLElement
    firstBlock: HTMLElement
    loader: HTMLElement
    firstTitle: HTMLElement
    firstText: HTMLElement
    secondBlock: HTMLElement
    secondTitle: HTMLElement
    secondText: HTMLElement
    thirdBlock: HTMLElement
    thirdTitle: HTMLElement
    thirdText: HTMLElement
  }

  animateTextSequence() {
    gsap.set(this.$refs.firstText, { opacity: 1 })
    gsap.set(this.$refs.secondText, { opacity: 0, y: 80 })
    gsap.set(this.$refs.thirdText, { opacity: 0, y: 80 })

    const secondLines = new SplitText(this.$refs.secondTitle, { type: 'lines' })
      .lines
    const thirdLines = new SplitText(this.$refs.thirdTitle, { type: 'lines' })
      .lines

    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.$refs.scrolltrigger,
          scrub: 0.5,
          invalidateOnRefresh: true,
          start: `top ${this.triggerPosition}`,
          end: () => this.scrollLength,
        },
      })
      .to(this.$refs.secondBlock, { opacity: 1, duration: 0.00001 }, '<')
      .to(this.$refs.thirdBlock, { opacity: 1, duration: 0.00001 }, '<')
      .to(this.$refs.firstBlock, { opacity: 0, y: -20, duration: 1 }, '+=2')
      .fromTo(
        secondLines,
        { y: 80, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.3, stagger: 0.1 }
      )
      .to(this.$refs.secondText, { opacity: 1, y: 0, duration: 1 }, '<25%')
      .to(
        this.$refs.secondBlock,
        { opacity: 0, yPercent: -20, duration: 1 },
        '+=2'
      )
      .fromTo(
        thirdLines,
        { y: 80, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.3, stagger: 0.1 }
      )
      .to(this.$refs.thirdText, { opacity: 1, y: 0, duration: 1 }, '<25%')
  }

  animateKeyFrame(images: HTMLImageElement[]) {
    const canvas = this.$refs.canvas as HTMLCanvasElement
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

    let canvasWidth: number
    let ratio: number
    let rescaleFactor: number = 1
    let canvasHeight: number
    let width: number
    const onResize = () => {
      ScrollTrigger.matchMedia({
        '(max-width: 768px)'() {
          rescaleFactor = 1.3
        },
        '(min-width: 769px) and (max-width: 1023px)'() {
          rescaleFactor = 0.8
        },
        '(min-width: 1024px)'() {
          rescaleFactor = 1
        },
      })
      if (this.$refs.canvasWrapper.clientHeight / window.innerWidth > 1.2) {
        canvasWidth = this.$refs.scrolltrigger.clientWidth
        ratio = canvasWidth / images[0].width
        canvasHeight = images[0].height * ratio
      } else {
        canvasHeight = this.$refs.canvasContainer.clientHeight
        ratio = canvasHeight / images[0].height
        canvasWidth = images[0].width * ratio
      }
      ctx.canvas.width = canvasWidth * rescaleFactor
      ctx.canvas.height = canvasHeight * rescaleFactor
    }

    onResize()
    window.addEventListener('resize', () => {
      if (window.innerWidth !== width) {
        width = window.innerWidth
        onResize()
      }
    })

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.$refs.scrolltrigger,
        scrub: true,
        invalidateOnRefresh: true,
        start: `top ${this.triggerPosition}`,
        end: () => this.scrollLength,
      },
    })

    const counter = { i: 0 }
    this.timeline.to(
      counter,
      {
        i: images.length - 1,
        roundProps: 'i',
        ease: 'none',
        immediateRender: true,
        onUpdate: () => calcDrawImage(ctx, images[counter.i]),
      },
      0
    )
  }

  setScrollLength() {
    if (this.isMobile) {
      this.scrollLength = '+=250%'
    } else {
      this.scrollLength = '+=325%'
    }
  }

  checkWebpSupport(): 'webp' | 'jpg' {
    const $canvas = document.createElement('canvas')

    return $canvas.getContext &&
      $canvas.getContext('2d') &&
      $canvas.toDataURL('image/webp').includes('data:image/webp')
      ? 'webp'
      : 'jpg'
  }

  createUrls(fileformat: 'webp' | 'jpg'): string[] {
    const urls: string[] = []

    ScrollTrigger.matchMedia({
      '(max-width: 768px)'() {
        for (let i = 1; i < 77; i++) {
          urls.push(`/images/frame-${i}-mobile.${fileformat}`)
        }
      },
      '(min-width: 769px)'() {
        for (let i = 1; i < 77; i++) {
          urls.push(`/images/frame-${i}.${fileformat}`)
        }
      },
    })

    return urls
  }

  async animate() {
    const fileformat = this.checkWebpSupport()
    const urls = this.createUrls(fileformat)
    const images = await preloadImages(urls)

    EventBus.$emit(ANIMATION_SECTION_READY)

    this.animateKeyFrame(images)
    this.animateTextSequence()
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 1024px)').matches
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.mobileCheck()
      this.setScrollLength()
    })
    this.mobileCheck()
    this.setScrollLength()
    this.animate()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
