import Vue from 'vue'

import LinkC from '~/components/LinkC.vue'
Vue.component('link-c', LinkC)

import TextC from '~/components/TextC.vue'
Vue.component('text-c', TextC)

import ImageC from '~/components/ImageC.vue'
Vue.component('image-c', ImageC)

import Modal from '~/components/Modal.vue'
Vue.component('modal', Modal)

import FormContact from '~/components/FormContact.vue'
Vue.component('form-contact', FormContact)

import InputText from '~/components/InputText.vue'
Vue.component('input-text', InputText)

import InputSelect from '~/components/InputSelect.vue'
Vue.component('input-select', InputSelect)

import InputRadioGroup from '~/components/InputRadioGroup.vue'
Vue.component('input-radio-group', InputRadioGroup)

import InputTextarea from '~/components/InputTextarea.vue'
Vue.component('input-textarea', InputTextarea)

import InputCheckbox from '~/components/InputCheckbox.vue'
Vue.component('input-checkbox', InputCheckbox)

import InputPhone from '~/components/InputPhone.vue'
Vue.component('input-phone', InputPhone)

import InputCountry from '~/components/InputCountry.vue'
Vue.component('input-country', InputCountry)

import SectionHead from '~/components/SectionHead.vue'
Vue.component('section-head', SectionHead)

import ProductDetails from '~/components/bloks/product-details/index.vue'
Vue.component('blok-product-details', ProductDetails)

import Carousel from '~/components/bloks/carousel/index.vue'
Vue.component('blok-carousel', Carousel)

import Banner from '~/components/bloks/banner/index.vue'
Vue.component('blok-banner', Banner)

import IconC from '~/components/IconC.vue'
Vue.component('icon-c', IconC)

import ProgressBar from '~/components/ProgressBar.vue'
Vue.component('ProgressBar', ProgressBar)

import Intro from '~/components/bloks/intro/index.vue'
Vue.component('blok-intro', Intro)

import Scrollanimation from '~/components/bloks/scrollanimation/index.vue'
Vue.component('blok-scrollanimation', Scrollanimation)

import Facts from '~/components/bloks/facts/index.vue'
Vue.component('blok-facts', Facts)

import Steps from '~/components/bloks/steps/index.vue'
Vue.component('blok-steps', Steps)

import TextContentImageGrid from '~/components/TextContentImageGrid.vue'
Vue.component('TextContentImageGrid', TextContentImageGrid)

import BigImage from '~/components/bloks/big-image/index.vue'
Vue.component('blok-big-image', BigImage)

import Textcontent from '~/components/bloks/textcontent/index.vue'
Vue.component('blok-textcontent', Textcontent)

import Faq from '~/components/bloks/faq/index.vue'
Vue.component('blok-faq', Faq)

import ProjectGallery from '~/components/bloks/project-gallery/index.vue'
Vue.component('blok-project-gallery', ProjectGallery)

import Loader from '~/components/Loader.vue'
Vue.component('loader', Loader)
