

































import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'

@Component({})
export default class InputText extends Vue {
  @Prop({ default: 'text' }) type!: 'text' | 'email' | 'tel' | 'date'
  @Prop() label!: string
  @Prop() value!: string
  @Prop() placeholder!: string
  @Prop() isCombined!: boolean
  @Prop() error!: string

  isActive: boolean = false

  mounted() {
    if (this.placeholder || this.value) {
      this.onExpandText()
    }
  }

  onExpandText() {
    gsap.to(this.$refs.label as HTMLElement, { scale: 0.8, marginTop: '-8px' })
  }

  onCollapseText() {
    gsap.to(this.$refs.label as HTMLElement, { scale: 1, marginTop: '0px' })
  }

  onFocus() {
    const input = this.$refs.input as InputText
    this.$emit('focus', (this.isActive = true))

    if (input.value === '') {
      this.onExpandText()
    }
  }

  onFocusOut() {
    const input = this.$refs.input as InputText
    this.$emit('focus', (this.isActive = false))

    if (input.value === '') {
      this.onCollapseText()
    }
  }
}
