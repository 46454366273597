






















































































































































import { Richtext } from 'storyblok-js-client'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { directive } from 'vue-awesome-swiper'
import { gsap } from 'gsap'
import { IStoryLink } from '~/types'

@Component({
  directives: {
    swiper: directive,
  },
})
export default class Faq extends Vue {
  @Prop() blok!: {
    title: string
    text: Richtext
    is_compact: boolean
    button: IStoryLink[]
    tabs: {
      title: string
      accordion: {
        title: string
        text: Richtext
      }[]
    }[]
  }

  isMobile: boolean = false
  tabIndexActive: number = 0
  accordionIndexActive: number = -1

  tabSwiperOption: object = {
    slidesPerView: 'auto',
    spaceBetween: 32,
  }

  get tabActive() {
    return this.blok.tabs[this.tabIndexActive]
  }

  onTab(index: number) {
    this.accordionIndexActive = -1
    this.tabIndexActive = index
    this.closeAllAccordions()
  }

  closeAllAccordions() {
    const allText = this.$refs.text as HTMLElement[]
    allText.forEach((text) => {
      gsap.to(text, {
        opacity: 0,
        height: 0,
        marginTop: 0,
        'clip-path': 'inset(0 0 100% 0%)',
      })
    })
  }

  onAccordion(index: number) {
    this.closeAllAccordions()
    const allText = this.$refs.text as HTMLElement[]
    const activeText = allText[index]

    if (this.accordionIndexActive === index) {
      this.accordionIndexActive = -1
    } else {
      this.accordionIndexActive = index
      gsap.to(activeText, {
        opacity: 1,
        height: 'auto',
        marginTop: 24,
        'clip-path': 'inset(0 0 0% 0%)',
      })
    }
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches
  }

  mounted() {
    window.addEventListener('resize', this.mobileCheck)
    this.mobileCheck()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
