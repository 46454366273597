import { ActionContext, ActionTree, GetterTree } from 'vuex'
import Storyblok, { StoryblokResult, StoryData } from 'storyblok-js-client'
import { IFormContactLongData, IFormContactShortData, ISettings } from '~/types'
import axios from 'axios'

interface RootState {
  version: 'draft' | 'published'
  cacheVersion: string
  settings: ISettings | null
  story: StoryData | null
  locales: string[]
  locale: string
  geoDetected: string
}

export const state = (): RootState => ({
  version: 'draft',
  cacheVersion: '',
  settings: null,
  story: null,
  locales: [],
  locale: '',
  geoDetected: '',
})

export const mutations = {
  setVersion(state: RootState, version: 'draft' | 'published') {
    state.version = version
  },
  setSettings(state: RootState, settings: ISettings) {
    state.settings = settings
  },
  setCacheVersion(state: RootState, version: string) {
    state.cacheVersion = version
  },
  setStory(state: RootState, story: StoryData) {
    state.story = story
  },
  setLocales(state: RootState, locales: string[]) {
    state.locales = locales
  },
  setLocale(state: RootState, locale: string) {
    state.locale = locale
  },
  setGeoDetected(state: RootState, geoDetected: string) {
    state.geoDetected = geoDetected
  },
}

export const getters: GetterTree<RootState, any> = {
  cacheVersion: (state: RootState) => state.cacheVersion,
  settings: (state: RootState) => state.settings,
  story: (state: RootState) => state.story,
  locale: (state: RootState) => state.locale,
  locales: (state: RootState) => state.locales,
  geoDetected: (state: RootState) => state.geoDetected,
}

export const actions: ActionTree<RootState, any> = {
  loadSettings(ctx: ActionContext<RootState, any>) {
    return ((this as any).$storyapi as Storyblok)
      .get(`cdn/stories/${ctx.state.locale}/settings`, {
        version: ctx.state.version,
        cv: ctx.state.cacheVersion,
      })
      .then((res: StoryblokResult) => {
        ctx.commit('setSettings', res.data.story.content)
      })
  },
  loadPage(ctx: ActionContext<RootState, any>, payload: { slug: string }) {
    return ((this as any).$storyapi as Storyblok)
      .get(`cdn/stories/${ctx.state.locale}/page/${payload.slug}`, {
        version: ctx.state.version,
        cv: ctx.state.cacheVersion,
      })
      .then((res: StoryblokResult) => {
        ctx.commit('setStory', res.data.story)
      })
  },
  formContactSendShort(
    ctx: ActionContext<RootState, any>,
    payload: { data: IFormContactShortData }
  ) {
    const url = '/api/form/contact/'

    return axios.post(url, payload.data)
  },
  formContactSendLong(
    ctx: ActionContext<RootState, any>,
    payload: { data: IFormContactLongData }
  ) {
    const url = '/api/form/quote/'

    return axios.post(url, payload.data)
  },
}
