










































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { Richtext } from 'storyblok-js-client'
import ButtonContact from '../../ButtonContact.vue'
import { ILinkItem, IStoryAsset } from '~/types'

gsap.registerPlugin(ScrollTrigger)

@Component({
  components: { ButtonContact },
})
export default class Intro extends Vue {
  @Prop() blok!: {
    first_title: Richtext
    second_title: Richtext
    third_title: Richtext
    link_item: ILinkItem[]
    videos: IStoryAsset[]
    images: IStoryAsset[]
  }

  isMobile: boolean = false
  timeline: GSAPTimeline = gsap.timeline()
  allAssets = this.$refs.asset as HTMLElement[]
  scrollLength: string = '+=400%'

  $refs!: {
    headline1: HTMLElement
    headline2: HTMLElement
    headline3: HTMLElement
    textBlock1: HTMLElement
    textBlock2: HTMLElement
    textBlock3: HTMLElement
    yellowForm: HTMLElement
    intro: HTMLElement
    asset: HTMLElement[]
  }

  getAsset(index: number) {
    const allAssets = this.$refs.asset as HTMLElement[]
    return allAssets[index] as HTMLElement
  }

  animateMe() {
    gsap.set(this.$refs.textBlock1, { opacity: 0, yPercent: 5 })
    gsap.set(this.$refs.textBlock2, { opacity: 0, yPercent: 5 })
    gsap.set(this.$refs.textBlock3, { opacity: 0, yPercent: 5 })

    this.timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: this.$refs.intro,
          scrub: 0.7,
          invalidateOnRefresh: true,
          start: 'top top',
          end: () => this.scrollLength,
        },
      })
      .fromTo(
        this.$refs.textBlock1,
        { opacity: 1, yPercent: 0 },
        { opacity: 0, yPercent: -5, duration: 0.5 }
      )
      .to(
        this.getAsset(2),
        { 'clip-path': 'inset(0 0 100% 0%)', duration: 1 },
        '<50%'
      )
      .to(
        this.$refs.textBlock2,
        { opacity: 1, yPercent: 0, duration: 1 },
        '<20%'
      )
      .to(this.$refs.textBlock2, { yPercent: -5, opacity: 0, duration: 0.5 })
      .to(
        this.getAsset(1),
        { 'clip-path': 'inset(0 0 100% 0%)', duration: 1 },
        '<50%'
      )
      .to(
        this.$refs.textBlock3,
        { opacity: 1, yPercent: 0, duration: 1 },
        '<20%'
      )
      .to(this.$refs.yellowForm, { yPercent: -60, duration: 1 })
    if (!this.isMobile) {
      this.timeline.to(
        this.$refs.textBlock3,
        { yPercent: -40, opacity: 0, duration: 0.5 },
        '<'
      )
    }
  }

  setScrollLength() {
    if (this.isMobile) {
      this.scrollLength = '+=250%'
    } else {
      this.scrollLength = '+=400%'
    }
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 1024px)').matches
  }

  mounted() {
    this.resetAnimations()
    window.addEventListener('resize', () => {
      this.mobileCheck()
      this.setScrollLength()
    })
    this.mobileCheck()
    this.setScrollLength()
    this.animateMe()
  }

  resetAnimations() {
    this.timeline.restart()
    this.timeline.kill()
    gsap.set(this.$refs.textBlock1, { clearProps: 'all' })
    gsap.set(this.$refs.textBlock2, { clearProps: 'all' })
    gsap.set(this.$refs.textBlock3, { clearProps: 'all' })
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
