


















































import { Richtext } from 'storyblok-js-client'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IStoryAsset, IStoryLink } from '~/types'

@Component({})
export default class Banner extends Vue {
  @Prop() blok!: {
    head: {
      label: string
      title: string
    }[]
    background: IStoryAsset
    title: string
    has_margin_bottom: boolean
    has_overlay: boolean
    text: Richtext
    button: IStoryLink[]
  }
}
