import { render, staticRenderFns } from "./TextContentImageGrid.vue?vue&type=template&id=590e6e7a&"
import script from "./TextContentImageGrid.vue?vue&type=script&lang=ts&"
export * from "./TextContentImageGrid.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageC: require('/vercel/path0/components/ImageC.vue').default})
