import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=217f345e&"
import script from "./Gallery.vue?vue&type=script&lang=ts&"
export * from "./Gallery.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageC: require('/vercel/path0/components/ImageC.vue').default,IconC: require('/vercel/path0/components/IconC.vue').default})
