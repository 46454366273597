module.exports = {
  content: [
    './components/**/*.{vue,js}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './bloks/**/*.vue',
    './plugins/**/*.{js,ts}',
    './nuxt.config.{js,ts}',
  ],
  variants: {
    extend: {
      padding: ['last'],
      margin: ['last'],
    },
  },
  theme: {
    colors: {
      transparent: 'transparent',
      white: '#ffffff',
      gray: {
        200: '#EBECE6',
        300: '#D9DAD6',
        500: '#97999b',
        700: '#666666',
        900: '#36393F',
      },
      black: '#000000',
      yellow: {
        300: '#FFF365',
        350: '#fadb61',
        400: '#FDAB0E',
      },
      red: '#FF0000',
      orange: {
        100: '#fff1e8',
        300: '#fa995a',
        400: '#FD7C29',
        500: '#DC671C',
      },
    },
    extend: {
      fontSize: {
        '10xl': '9.5rem',
        '8xl': '6.25rem',
        '7xl': '5.5rem',
        '6xl': '4rem',
        '5xl': '3.375rem',
        '4xl': '3rem',
        '3-5xl': '2.5rem',
        '3xl': '2rem',
      },
      spacing: {
        21: '5.5rem',
      },
      height: {
        168: '42rem',
        111: '27.75rem',
      },
      lineHeight: {
        'super-extra-tight': '1.1',
        'super-tight': '1.2',
      },
      fontFamily: {
        firsneue: ['FirsNeue'],
        gellix: ['Gellix'],
      },
      fontWeight: {
        demibold: 450,
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
}
