




































































import { Richtext } from 'storyblok-js-client'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { IStoryAsset } from '~/types'
gsap.registerPlugin(ScrollTrigger)

@Component({})
export default class Facts extends Vue {
  @Prop() blok!: {
    head: {
      title: string
      label: string
    }[]
    items: {
      title: string
      number: number
      text: Richtext
      image: IStoryAsset
    }[]
  }

  getNumber(index: number) {
    const allNumbers = this.$refs.number as HTMLElement[]
    return allNumbers[index] as HTMLElement
  }

  getItem(index: number) {
    return this.$refs[`item-${index}`] as HTMLElement
  }

  getImage(index: number) {
    const allImages = this.$refs.image as HTMLElement[]
    return allImages[index] as HTMLElement
  }

  getText(index: number) {
    return this.$refs[`text-${index}`] as HTMLElement
  }

  numberWithCommas(x: string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  factIndexActive: number = -1
  isMobile: boolean = false
  mouseX: number = 0
  mouseY: number = 0

  numberScrollAnimation() {
    this.blok.items.forEach((_, i) => {
      const number = this.getNumber(i)
      gsap
        .timeline({
          scrollTrigger: {
            trigger: number,
            toggleActions: 'play none none none',
            start: 'top bottom',
            end: '+=1',
          },
        })
        .from(this.getNumber(i), {
          textContent: 0,
          duration: 1,
          snap: { textContent: 1 },
          stagger: {
            each: 1.0,
          },
        })
    })
  }

  setAnimationStart() {
    this.blok.items.forEach((_, i) => {
      const item = this.getItem(i)
      const image = this.getImage(i)
      gsap.defaults({ ease: 'Power4.easeOut ', duration: 0.25 })
      gsap.set(this.$refs.imageWrap as HTMLElement, { opacity: 1 })

      const onResize = () => {
        ScrollTrigger.matchMedia({
          '(min-width: 1024px)'() {
            gsap.set(item, { opacity: 0.5 })
            gsap.set(image, { opacity: 0, height: '100%' })
          },
          '(max-width: 1023px)'() {
            gsap.set(item, { opacity: 1 })
            gsap.set(image, { opacity: 1, scale: 1, height: '100%', margin: 0 })
          },
        })
      }
      onResize()
      window.addEventListener('resize', onResize)
    })
  }

  onEnter(index: number) {
    gsap.to(this.getItem(index), { opacity: 1, scale: 1 })
    gsap.to(this.getImage(index), { opacity: 1 })
  }

  onLeave(index: number) {
    gsap.to(this.getItem(index), { opacity: 0.5 })
    gsap.to(this.getImage(index), { opacity: 0, scale: 0.92 })
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 1023px)').matches
  }

  mounted() {
    window.addEventListener('resize', this.mobileCheck)
    this.mobileCheck()
    this.setAnimationStart()
    this.numberScrollAnimation()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
