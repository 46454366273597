import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5e9d7e32&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextC: require('/vercel/path0/components/TextC.vue').default,ButtonContact: require('/vercel/path0/components/ButtonContact.vue').default,IconC: require('/vercel/path0/components/IconC.vue').default,ImageC: require('/vercel/path0/components/ImageC.vue').default})
