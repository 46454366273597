






























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class InputSelect extends Vue {
  @Prop() value!: string
  @Prop() label!: string
  @Prop() options!: { id: string; label: string }[]
  @Prop() error!: string

  get _value(): string {
    return this.value
  }

  set _value(val: string) {
    this.$emit('input', val)
  }
}
