












































import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { IStoryAsset } from '~/types'

gsap.registerPlugin(ScrollTrigger)

@Component({})
export default class BigImage extends Vue {
  @Prop() blok!: {
    head: {
      label: string
      title: string
    }[]
    image: IStoryAsset
  }

  isMobile: boolean = false

  $refs!: {
    imageWrap: HTMLElement
    backgroundImage: HTMLElement
    head: HTMLElement
    scrollTrigger: HTMLElement
    mask: SVGElement
    clip: SVGClipPathElement
  }

  animate() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.$refs.imageWrap,
          scrub: 0.6,
          start: 'top 70%',
          end: '+=100%',
        },
      })
      .fromTo(this.$refs.imageWrap, { yPercent: 0 }, { yPercent: -10 }, '<')
      .fromTo(
        this.$refs.backgroundImage,
        { yPercent: -10 },
        { yPercent: 10 },
        '<'
      )
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 1024px)').matches
  }

  mounted() {
    window.addEventListener('resize', this.mobileCheck)
    this.mobileCheck()
    if (!this.isMobile) {
      this.animate()
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
