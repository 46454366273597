









































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import FormContactShort from './FormContactShort.vue'
import FormContactLong from './FormContactLong.vue'
import { IFormField, ISettings } from '~/types'

@Component({
  components: {
    FormContactShort,
    FormContactLong,
  },
})
export default class FormContact extends Vue {
  @Prop() indexOpen!: number
  @Prop() isSuccessReset!: boolean

  @Getter settings!: ISettings

  activeIndex: number = 0
  isSuccess: boolean = false

  @Watch('indexOpen')
  watchIndexOpen(indexOpen: number) {
    this.activeIndex = indexOpen
  }

  @Watch('isSuccessReset')
  watchIsReset(isSuccessReset: boolean) {
    if (isSuccessReset) {
      this.isSuccess = false
    }
  }

  get formShortFields(): { [id: string]: IFormField } | null {
    return this.settings.form_short_v2
      ? Object.fromEntries(
          this.settings.form_short_v2.fields.map((field) => [field.id, field])
        )
      : null
  }

  get formLongFields(): { [id: string]: IFormField } | null {
    return this.settings.form_long_v2
      ? Object.fromEntries(
          this.settings.form_long_v2.fields.map((field) => [field.id, field])
        )
      : null
  }

  get translations(): {
    title: string
    successTitle: string
    successText: string
    successButton: string
  } | null {
    if (this.formShortFields === null || this.formLongFields === null) {
      return null
    }

    return {
      title:
        this.activeIndex === 0
          ? this.formShortFields.title.label
          : this.formLongFields.title.label,
      successTitle:
        this.activeIndex === 0
          ? this.formShortFields['success-title'].label
          : this.formLongFields['success-title'].label,
      successText:
        this.activeIndex === 0
          ? this.formShortFields['success-text'].label
          : this.formLongFields['success-text'].label,
      successButton:
        this.activeIndex === 0
          ? this.formShortFields['success-button'].label
          : this.formLongFields['success-button'].label,
    }
  }
}
