




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class IconC extends Vue {
  @Prop() id!: string
  @Prop({ default: '#97999B' }) color!: string

  get iconMap(): { [k: string]: string } {
    return {
      close:
        '<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 15.8608L17 2.00015" stroke="#36393F" stroke-width="4" stroke-linecap="round"/><path d="M3 2.13916L17 15.9999" stroke="#36393F" stroke-width="4" stroke-linecap="round"/></svg>',
      closeSmall:
        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 2L2 18" stroke="#FD7C29" stroke-width="3"/><path d="M18 18L2.61538 2.61538" stroke="#FD7C29" stroke-width="3"/></svg>',
      menu: '<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0.5H23.5V3.5H0.5V0.5Z" fill="#36393F"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 8.5H23.5V11.5H0.5V8.5Z" fill="#36393F"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 16.5H23.5V19.5H0.5V16.5Z" fill="#36393F"/></svg>',
      yellowFormBig:
        '<svg width="1360" height="3244" viewBox="0 0 1360 3244" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1264.06 1866.77C1325.59 1799.09 1360 1708.57 1360 1614.36C1360 1520.15 1325.59 1429.64 1264.06 1361.95L3.7092e-05 0L3.7092e-05 3244L1264.06 1866.77Z" fill="#FFF365"/></svg>',
      yellowFormSmall:
        '<svg width="607" height="375" viewBox="0 0 607 375" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M606.462 375H0V13.5999C26.9312 4.68262 55.5913 0 84.7684 0C149.297 0 211.297 22.9033 257.659 63.8677L606.462 375Z" fill="#FFF365"/></svg>',
      arrowRight:
        '<svg width="100%" height="100%" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L10.4853 10.4853L2 18.9706" stroke="#FD7C29" stroke-width="3" stroke-linejoin="round"/></svg>',
      arrowLeft:
        '<svg width="100%" height="100%" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6758 2L2.1905 10.4853L10.6758 18.9706" stroke="#FD7C29" stroke-width="3" stroke-linejoin="round"/></svg>',
      arrowLongLeft:
        '<svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 8.7073C-0.0976311 8.31677 -0.0976311 7.68361 0.292893 7.29308L6.65685 0.929124C7.04738 0.538599 7.68054 0.538599 8.07107 0.929124C8.46159 1.31965 8.46159 1.95281 8.07107 2.34334L3.41421 7.00019H27C27.5523 7.00019 28 7.44791 28 8.00019C28 8.55248 27.5523 9.00019 27 9.00019H3.41421L8.07107 13.657C8.46159 14.0476 8.46159 14.6807 8.07107 15.0713C7.68054 15.4618 7.04738 15.4618 6.65685 15.0713L0.292893 8.7073Z" fill="#FD7C29"/></svg>',
      arrowCircleLeft: `<svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24.1719" cy="23.5" r="22.2182" stroke="${this.color}" stroke-width="2.56364"/><path d="M20.7034 28.6387C19.5472 27.5099 18.8784 25.9743 18.8398 24.3597L18.8398 24.2389C18.8772 22.6235 19.5472 21.0872 20.706 19.9599L26.7368 13.7884C26.7715 13.7519 26.8132 13.7228 26.8594 13.703C26.9057 13.6831 26.9555 13.6729 27.0058 13.6729C27.0561 13.6729 27.1059 13.6831 27.1522 13.703C27.1984 13.7228 27.2401 13.7519 27.2748 13.7884C27.8421 14.4122 28.3585 15.0803 28.8192 15.7864C28.8649 15.857 28.8852 15.941 28.8768 16.0247C28.8684 16.1083 28.8317 16.1866 28.7728 16.2467L22.8733 22.2897C22.6052 22.5506 22.3922 22.8625 22.2467 23.207C22.1012 23.5514 22.0263 23.9215 22.0263 24.2954C22.0263 24.6693 22.1012 25.0394 22.2467 25.3839C22.3922 25.7284 22.6052 26.0403 22.8733 26.3012L28.9813 32.1719C29.0418 32.2306 29.0806 32.3081 29.0913 32.3917C29.1021 32.4753 29.0842 32.56 29.0405 32.6322C28.5963 33.3569 28.0945 34.0449 27.5399 34.6893C27.5065 34.7269 27.4658 34.7573 27.4204 34.7787C27.3749 34.8002 27.3255 34.8122 27.2753 34.8141C27.2251 34.816 27.175 34.8078 27.128 34.7899C27.081 34.772 27.0381 34.7448 27.0019 34.7099L20.7034 28.6387Z" fill="${this.color}"/></svg>`,
      arrowCircleRight: `<svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="23.8262" cy="23.5" r="22.2182" stroke="${this.color}" stroke-width="2.56364"/><path d="M28.5954 28.6387C29.7516 27.5099 30.4204 25.9743 30.459 24.3597L30.459 24.2389C30.4216 22.6235 29.7516 21.0872 28.5928 19.9599L22.562 13.7884C22.5274 13.7519 22.4857 13.7228 22.4394 13.703C22.3932 13.6831 22.3434 13.6729 22.293 13.6729C22.2427 13.6729 22.1929 13.6831 22.1466 13.703C22.1004 13.7228 22.0587 13.7519 22.024 13.7884C21.4567 14.4122 20.9403 15.0803 20.4797 15.7864C20.4339 15.857 20.4136 15.941 20.422 16.0247C20.4304 16.1083 20.4671 16.1866 20.526 16.2467L26.4256 22.2897C26.6936 22.5506 26.9067 22.8625 27.0521 23.207C27.1976 23.5514 27.2725 23.9215 27.2725 24.2954C27.2725 24.6693 27.1976 25.0394 27.0521 25.3839C26.9067 25.7284 26.6936 26.0403 26.4256 26.3012L20.3175 32.1719C20.257 32.2306 20.2182 32.3081 20.2075 32.3917C20.1967 32.4753 20.2146 32.56 20.2583 32.6322C20.7025 33.3569 21.2043 34.0449 21.7589 34.6893C21.7923 34.7269 21.833 34.7573 21.8785 34.7787C21.9239 34.8002 21.9733 34.8122 22.0235 34.8141C22.0738 34.816 22.1239 34.8078 22.1709 34.7899C22.2178 34.772 22.2607 34.7448 22.2969 34.7099L28.5954 28.6387Z" fill="${this.color}"/></svg>`,
      letter:
        '<svg width="86" height="74" viewBox="0 0 86 74" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.89918 9.62451C1.70991 9.62451 0.746094 10.5829 0.746094 11.7653C0.746094 12.9477 1.70988 13.9067 2.89918 13.9067H5.22545C6.41403 13.9067 7.37784 12.9477 7.37784 11.7653C7.37784 10.5828 6.41405 9.62451 5.22545 9.62451H2.89918Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.5554 9.62451C10.3662 9.62451 9.40234 10.5829 9.40234 11.7653C9.40234 12.9477 10.3661 13.9067 11.5554 13.9067H28.5252H28.5245C29.7138 13.9067 30.6776 12.9477 30.6776 11.7653C30.6776 10.5828 29.7138 9.62451 28.5245 9.62451H11.5547H11.5554Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.89918 1.84961C1.70991 1.84961 0.746094 2.80795 0.746094 3.99035C0.746094 5.17275 1.70988 6.13109 2.89918 6.13109H39.7362H39.7355C40.9248 6.13109 41.8886 5.17275 41.8886 3.99035C41.8886 2.80795 40.9248 1.84961 39.7355 1.84961H2.8985H2.89918Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M59.0015 0.828885L3.37386 31.8689C0.222384 33.66 -0.933011 37.6876 0.818078 40.833C6.71555 51.2391 12.7848 61.7014 17.6675 70.6699C19.468 73.8043 23.5181 74.9195 26.6812 73.1789L82.3088 42.1058C85.4813 40.3514 86.6136 36.3163 84.8646 33.1751C79.3604 23.178 73.4861 13.3604 68.0483 3.33824C66.3218 0.268313 62.4477 -0.959761 59.0015 0.829232V0.828885ZM60.2797 5.04325L50.7622 38.7592C49.4772 43.1788 44.8376 45.7822 40.3697 44.5127L6.43467 35.0468L60.2797 5.04325ZM64.5172 5.84607L81.0977 35.2482C81.7239 36.4674 81.1969 37.7449 80.2236 38.3922C61.6156 48.6379 43.1971 59.2519 24.5629 69.4323C23.4606 70.0313 22.0431 69.6612 21.4012 68.5629C15.8692 58.7126 10.284 48.8681 4.71952 39.0269L39.1919 48.6266C45.9288 50.5353 52.9761 46.5741 54.8976 39.9301L64.5172 5.84607Z" fill="black"/></svg>',
      plus: '<svg width="67" height="66" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M66.2406 29.6802H36.6406V0.240234H30.0806V29.6802H0.640625V36.2402H30.0806V65.8402H36.6406V36.2402H66.2406V29.6802Z" fill="#FD7C29"/></svg>',
    }
  }

  get icon() {
    return this.iconMap[this.id]
  }
}
