

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class Modal extends Vue {
  @Prop() isOpen!: boolean

  onClose() {
    this.$emit('close')
  }

  @Watch('isOpen')
  watchModalOpen(isOpen: boolean) {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }

  mounted() {
    this.watchModalOpen(this.isOpen)
  }
}
