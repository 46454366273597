




















































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { IFormContactLongData, IFormField } from '~/types'

@Component({})
export default class FormContactLong extends Vue {
  @Prop() fields!: {
    text: IFormField
    subtitle: IFormField
    salutation: IFormField
    firstName: IFormField
    lastName: IFormField
    company: IFormField
    street: IFormField
    'street-num': IFormField
    postcode: IFormField
    city: IFormField
    email: IFormField
    country: IFormField
    tel: IFormField
    message: IFormField
    'project-title': IFormField
    'contruction-project': IFormField
    'builder-type': IFormField
    floors: IFormField
    'roof-orientation': IFormField
    'roof-area': IFormField
    'roof-pitch': IFormField
    'power-consumtion': IFormField
    'desired-install-date': IFormField
    'project-address-title': IFormField
    'project-address-checkbox': IFormField
    'project-address-street': IFormField
    'project-address-street-num': IFormField
    'project-address-postcode': IFormField
    'project-address-city': IFormField
    button: IFormField
    terms: IFormField
  }

  @Action formContactSendLong!: (p: {
    data: IFormContactLongData
  }) => Promise<void>

  isErrorMessageShown: boolean = false

  data: IFormContactLongData = {
    salutation: this.fields.salutation.items
      ? this.fields.salutation.items[0].id
      : '',
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    'street-num': '',
    postcode: '',
    city: '',
    country: '',
    email: '',
    tel: '',
    message: '',
    'contruction-project': '',
    'builder-type': '',
    floors: '',
    'roof-orientation': '',
    'roof-area': '',
    'roof-pitch': '',
    'power-consumtion': '',
    'project-address-checkbox': true,
    'desired-install-date': '',
    'project-address-street': '',
    'project-address-street-num': '',
    'project-address-postcode': '',
    'project-address-city': '',
    'project-address-country': '',
    terms: false,
    conference: this.$route.query.conference?.toString() || '',
  }

  getIsEmailValid(email: string): boolean {
    return /\S+@\S+\.\S+/.test(email)
  }

  getIsRequiredValid(val: string): boolean {
    return val.length > 0
  }

  get isValid(): boolean {
    return [
      this.getIsEmailValid(this.data.email),
      this.getIsRequiredValid(this.data.firstName),
      this.getIsRequiredValid(this.data.lastName),
      this.getIsRequiredValid(this.data.message),
      this.getIsRequiredValid(this.data.tel),
      this.getIsRequiredValid(this.data.street),
      this.getIsRequiredValid(this.data['street-num']),
      this.getIsRequiredValid(this.data.postcode),
      this.getIsRequiredValid(this.data.city),
      this.getIsRequiredValid(this.data.country),
      this.getIsRequiredValid(this.data['contruction-project']),
      this.getIsRequiredValid(this.data['builder-type']),
      this.getIsRequiredValid(this.data.floors),
      this.getIsRequiredValid(this.data['roof-orientation']),
      this.getIsRequiredValid(this.data['roof-area']),
      this.getIsRequiredValid(this.data['roof-pitch']),
      this.getIsRequiredValid(this.data['power-consumtion']),
      !this.data['project-address-checkbox']
        ? this.getIsRequiredValid(this.data['project-address-street'])
        : true,
      !this.data['project-address-checkbox']
        ? this.getIsRequiredValid(this.data['project-address-street-num'])
        : true,
      !this.data['project-address-checkbox']
        ? this.getIsRequiredValid(this.data['project-address-postcode'])
        : true,
      !this.data['project-address-checkbox']
        ? this.getIsRequiredValid(this.data['project-address-city'])
        : true,
      !this.data['project-address-checkbox']
        ? this.getIsRequiredValid(this.data['project-address-country'])
        : true,
      this.data.terms,
    ].every((v) => !!v)
  }

  async onShortForm() {
    this.isErrorMessageShown = false

    if (this.isValid === false) {
      this.isErrorMessageShown = true
      return
    }

    try {
      await this.formContactSendLong({ data: this.data })

      this.$emit('success')
      this.data = {
        salutation: this.fields.salutation.items
          ? this.fields.salutation.items[0].id
          : '',
        firstName: '',
        lastName: '',
        company: '',
        street: '',
        'street-num': '',
        postcode: '',
        city: '',
        country: '',
        email: '',
        tel: '',
        message: '',
        'contruction-project': '',
        'builder-type': '',
        floors: '',
        'roof-orientation': '',
        'roof-area': '',
        'roof-pitch': '',
        'power-consumtion': '',
        'project-address-checkbox': true,
        'desired-install-date': '',
        'project-address-street': '',
        'project-address-street-num': '',
        'project-address-postcode': '',
        'project-address-city': '',
        'project-address-country': '',
        terms: false,
        conference: this.$route.query.conference?.toString() || '',
      }
    } catch (e) {
      alert(e)
    }
  }
}
