









import { Vue, Component } from 'vue-property-decorator'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

@Component({})
export default class ProgressBar extends Vue {
  $refs!: {
    page: HTMLElement
    progressBar: HTMLElement
    progressTrack: HTMLElement
  }

  width: number = 0

  animateMe() {
    let scrollLength: number
    let endTrigger: string = '+=100%'
    this.width = window.innerWidth

    const onResize = () => {
      scrollLength = document.documentElement.scrollHeight
      endTrigger = '+=' + scrollLength + ' bottom'
    }
    onResize()

    window.addEventListener('resize', () => {
      if (window.innerWidth !== this.width) {
        this.width = window.innerWidth
        onResize()
      }
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.$refs.progressBar,
          scrub: 0.2,
          pinSpacing: false,
          start: 'top top',
          end: endTrigger,
        },
      })
      .fromTo(
        this.$refs.progressTrack,
        { scaleX: 0 },
        { scaleX: 1, ease: 'none' }
      )
  }

  mounted() {
    this.animateMe()
  }
}
