








































import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'

@Component({})
export default class InputSelect extends Vue {
  @Prop() value!: string
  @Prop() label!: string
  @Prop() isCombined!: boolean
  @Prop() options!: { id: string; label: string }[]
  @Prop() error!: string

  isActive: boolean = false

  get optionsFiltered() {
    return this.options.filter((o) => o.label)
  }

  mounted() {
    if (this.value) {
      this.onExpandText()
    }
  }

  onExpandText() {
    gsap.to(this.$refs.label as HTMLElement, { scale: 0.8, marginTop: '-8px' })
  }

  onCollapseText() {
    gsap.to(this.$refs.label as HTMLElement, { scale: 1, marginTop: '0' })
  }

  onFocus() {
    const select = this.$refs.select as HTMLSelectElement
    this.$emit('focus', (this.isActive = true))

    if (select.value === '') {
      this.onExpandText()
    }
  }

  onFocusOut() {
    const select = this.$refs.select as HTMLSelectElement
    this.$emit('focus', (this.isActive = true))
    if (select.value === '') {
      this.onCollapseText()
    }
  }
}
