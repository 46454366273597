import { render, staticRenderFns } from "./default.vue?vue&type=template&id=e9d47cf6&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/vercel/path0/components/Loader.vue').default,Gallery: require('/vercel/path0/components/Gallery.vue').default,Modal: require('/vercel/path0/components/Modal.vue').default,FormContact: require('/vercel/path0/components/FormContact.vue').default,Header: require('/vercel/path0/components/Header.vue').default,Footer: require('/vercel/path0/components/Footer.vue').default})
