import { render, staticRenderFns } from "./ImageC.vue?vue&type=template&id=14c5bbc6&scoped=true&"
import script from "./ImageC.vue?vue&type=script&lang=ts&"
export * from "./ImageC.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14c5bbc6",
  null
  
)

export default component.exports