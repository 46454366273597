

















































































































import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { EventBus, EVENT_TOGGLE_FORM_CONTACT } from '~/event-bus'
import { ISettings } from '~/types'

@Component({})
export default class Footer extends Vue {
  @Getter settings!: ISettings
  @Getter locale!: string
  @Getter locales!: string[]

  onLang() {
    this.$emit('lang')
  }

  onContactOpen() {
    EventBus.$emit(EVENT_TOGGLE_FORM_CONTACT, 0)
  }
}
