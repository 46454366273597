import { render, staticRenderFns } from "./FormContactLong.vue?vue&type=template&id=3e06755a&"
import script from "./FormContactLong.vue?vue&type=script&lang=ts&"
export * from "./FormContactLong.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputSelect: require('/vercel/path0/components/InputSelect.vue').default,InputText: require('/vercel/path0/components/InputText.vue').default,InputCountry: require('/vercel/path0/components/InputCountry.vue').default,InputPhone: require('/vercel/path0/components/InputPhone.vue').default,InputTextarea: require('/vercel/path0/components/InputTextarea.vue').default,InputRadioGroup: require('/vercel/path0/components/InputRadioGroup.vue').default,InputCheckbox: require('/vercel/path0/components/InputCheckbox.vue').default})
