





import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    text: {
      type: [String, Object],
      default: '',
    },
  },
  setup(props): { doc: any } {
    if (typeof props.text === 'string') {
      return { doc: props }
    }

    const doc = ref(props.text)

    return {
      doc,
    }
  },
})
