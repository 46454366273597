


























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { directive } from 'vue-awesome-swiper'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { Richtext } from 'storyblok-js-client'
import tailwindConfig from '../../../tailwind.config.js'
import { IStoryAsset } from '~/types'
gsap.registerPlugin(ScrollTrigger)

@Component({
  directives: {
    swiper: directive,
  },
})
export default class Steps extends Vue {
  @Prop() blok!: {
    _uid: string
    has_margin_bottom: boolean
    head: {
      label: string
      title: string
    }[]
    tabs: {
      title: string
      cards: {
        title: string
        text: Richtext
        icon: IStoryAsset
      }[]
    }[]
  }

  $refs!: {
    cards: HTMLElement
    card: HTMLElement
    cardContent: HTMLElement
  }

  isMobile: boolean = false
  tabIndexActive: number = 0
  iconLeftColor: string = tailwindConfig.theme.colors.gray[500]
  iconRightColor: string = tailwindConfig.theme.colors.gray[500]
  orange: string = tailwindConfig.theme.colors.orange[400]
  gray: string = tailwindConfig.theme.colors.gray[500]

  onTab(index: number) {
    ;(this as any).mySwiper.slideTo(0)
    this.tabIndexActive = index
    gsap
      .timeline()
      .fromTo(
        this.$refs.cardContent,
        { y: 0, opacity: 1 },
        { y: 10, opacity: 0, stagger: 0.05, duration: 0.1 }
      )
      .to(this.$refs.cardContent, {
        y: 0,
        opacity: 1,
        duration: 0.1,
        stagger: 0.05,
      })
  }

  get tabActive() {
    return this.blok.tabs[this.tabIndexActive]
  }

  tabSwiperOption: object = {
    slidesPerView: 'auto',
    spaceBetween: 32,
  }

  swiperOption: object = {
    spaceBetween: 20,
    slidesPerView: 1.2,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
      },
      768: {
        slidesPerView: 2.2,
      },
      1280: {
        slidesPerView: 3.2,
      },
    },
    pagination: {
      el: `#${this.blok._uid}carousel-swiper_pagination`,
      clickable: true,
    },
    navigation: {
      nextEl: `#${this.blok._uid}carousel-swiper_next`,
      prevEl: `#${this.blok._uid}carousel-swiper_prev`,
    },
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches
  }

  mounted() {
    window.addEventListener('resize', this.mobileCheck)
    this.mobileCheck()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
