import { Context } from '@nuxt/types'

export default async function (context: Context) {
  if (!process.server) return

  // get geo location from vercel
  context.store.commit(
    'setGeoDetected',
    context.req.headers['x-vercel-ip-country']
  )

  const version = process.env.VUE_IS_PREVIEW === 'true' ? 'draft' : 'published'

  // allow draft pages
  context.store.commit('setVersion', version)

  // cache only can be flushed/set on server side
  if (context.route.query.flushCache) {
    context.app.$storyapi.flushCache()
  }

  context.store.commit('setCacheVersion', context.app.$storyapi.cacheVersion)

  const locales: string[] = process.env.VUE_DEFAULT_LOCALES?.split(',') || []

  // set env locales
  context.store.commit('setLocales', locales)

  // slug can be locale
  const locale = context.route.params.locale

  // if locale is not found, redirect to first locale
  if (!locale) {
    return context.redirect(`/${locales[0]}/`)
  }

  // set locale or default locale
  context.store.commit('setLocale', locale)

  const isLangChanges: boolean = locale !== context.store.state.locale

  // only load settings if does not exist
  if (
    isLangChanges ||
    !context.store.state.settings ||
    !context.store.state.settings._uid
  ) {
    await context.store.dispatch('loadSettings')
  }
}
