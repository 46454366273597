import Vue, { FunctionalComponentOptions } from 'vue'
// @ts-ignore
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import { Block, Mark } from '@marvr/storyblok-rich-text-types'
import TextContentImageGrid from '../components/TextContentImageGrid.vue'
import { EventBus, EVENT_TOGGLE_FORM_CONTACT } from '~/event-bus'

const Heading: FunctionalComponentOptions = {
  functional: true,
  render(h, { props, data, children }) {
    return h(
      `h${data.props?.attrs.level}`,
      {
        ...data,
        ...props,
        class: {
          'text-gray-900': true,
          'font-firsneue text-3-5xl lg:text-5xl mb-5 md:mb-14 font-semibold':
            data.props?.attrs.level === 1,
          'font-firsneue text-3xl lg:text-5xl text-orange-400 mb-12 mt-12 font-regular':
            data.props?.attrs.level === 2,
          'font-firsneue text-2xl leading-snug lg:text-5xl':
            data.props?.attrs.level === 4,
        },
      },
      children
    )
  },
}
const Quote: FunctionalComponentOptions = {
  functional: true,
  render(h, { props, data, children }) {
    return h(
      'blockquote',
      {
        ...props,
        ...data,
        class: {
          'text-3xl lg:text-5xl leading-snug text-orange-400 mb-12 mt-12 font-regular':
            true,
        },
      },
      children
    )
  },
}

const Paragraph: FunctionalComponentOptions = {
  functional: true,
  render(h, { props, data, children }) {
    return h(
      'p',
      {
        ...props,
        ...data,
        class: {
          'text-lg font-gellix mb-6 last:mb-0': true,
        },
      },
      children
    )
  },
}

const Link: FunctionalComponentOptions = {
  functional: true,
  render(h, { props, data, children }) {
    return h(
      'a',
      {
        ...props,
        ...data,
        class: {
          underline: true,
        },
        on: {
          click: (event: Event) => {
            if (!props.attrs.href.includes('?contact-')) return
            event.preventDefault()
            const indexForm = parseInt(props.attrs.href.split('?contact-')[1])
            console.log({ indexForm })

            EventBus.$emit(EVENT_TOGGLE_FORM_CONTACT, indexForm)
          },
        },
      },
      children
    )
  },
}

Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      'text-content-image-grid': TextContentImageGrid,
    },
    blocks: {
      [Block.HEADING]: Heading,
      [Block.PARAGRAPH]: Paragraph,
      [Block.QUOTE]: Quote,
    },
    marks: {
      [Mark.LINK]: Link,
    },
  },
})
