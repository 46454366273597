var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-6 md:px-10 lg:px-28 xl:mx-auto xl:max-w-screen-xl xl:px-4"},[_c('SectionHead',{attrs:{"blok":_vm.blok.head[0]}})],1),_vm._v(" "),_c('div',{staticClass:"md:px-10 lg:px-28 xl:mx-auto xl:max-w-screen-xl xl:px-4"},[(_vm.isMobile)?_c('div',{directives:[{name:"swiper",rawName:"v-swiper:tabSwiper",value:(_vm.tabSwiperOption),expression:"tabSwiperOption",arg:"tabSwiper"}]},[_c('ul',{staticClass:"swiper-wrapper mb-6 flex px-6"},_vm._l((_vm.blok.products),function(product,index){return _c('li',{key:index,staticClass:"swiper-slide mr-12 w-auto cursor-pointer font-firsneue text-lg hover:text-orange-400",class:index === _vm.productIndexActive
              ? 'font-demibold text-orange-400 underline underline-offset-8'
              : 'font-normal text-gray-500',on:{"click":function($event){return _vm.onProduct(index)}}},[_vm._v("\n          "+_vm._s(product.title)+"\n        ")])}),0)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mb-21 px-6 md:mb-28 md:px-10 lg:px-28 xl:mx-auto xl:max-w-screen-xl xl:px-4"},[(!_vm.isMobile)?_c('div',[_c('ul',{staticClass:"mb-12 flex px-0"},_vm._l((_vm.blok.products),function(product,index){return _c('li',{key:index,staticClass:"mr-12 w-auto cursor-pointer font-firsneue text-2xl hover:text-orange-400",class:index === _vm.productIndexActive
              ? 'font-demibold text-orange-400 underline underline-offset-8'
              : 'font-normal text-gray-500',on:{"click":function($event){return _vm.onProduct(index)}}},[_vm._v("\n          "+_vm._s(product.title)+"\n        ")])}),0)]):_vm._e(),_vm._v(" "),(_vm.productActive)?_c('div',[_c('h2',{staticClass:"mb-16 max-w-4xl font-gellix text-lg font-semibold leading-relaxed text-gray-900 md:text-3-5xl lg:leading-snug"},[_vm._v("\n        "+_vm._s(_vm.productActive.subtitle)+"\n      ")]),_vm._v(" "),_c('ul',{staticClass:"flex"},_vm._l((_vm.productActive.variants),function(variant,variantIndex){return _c('li',{key:variantIndex,staticClass:"mb-10 flex cursor-pointer items-center justify-center font-firsneue",on:{"click":function($event){return _vm.onVariant(variantIndex)}}},[_c('div',{staticClass:"relative mr-2.5 h-9 w-9"},[_c('div',{class:variantIndex === _vm.productVariantIndexActive
                  ? 'absolute top-0 left-0 h-10 w-10 shrink-0 rounded-full border-2 border-orange-400  bg-white'
                  : 'border-white'}),_vm._v(" "),_c('div',{staticClass:"absolute top-1 left-1 h-8 w-8 shrink-0 rounded-full",style:({
                background: variant.color.color,
                backgroundImage: ("linear-gradient( -45deg, #ffffff30, #ffffff30 50%, " + (variant.color.color) + " 50% )"),
              })})]),_vm._v(" "),_c('span',{staticClass:"mr-6 text-base text-gray-900",class:variantIndex === _vm.productVariantIndexActive ? '' : 'hidden'},[_vm._v("\n            "+_vm._s(variant.title)+"\n          ")])])}),0),_vm._v(" "),_c('div',{staticClass:"mb-10 flex md:mb-28"},_vm._l((_vm.productVariantActive.images),function(image,variantImageIndex){return _c('div',{key:variantImageIndex,staticClass:"mr-2 last:mr-0 lg:mr-9"},[_c('ImageC',{staticClass:"flex rounded-2xl",attrs:{"src":image.filename,"alt":image.alt}})],1)}),0),_vm._v(" "),_c('ul',{staticClass:"specs grid-cols-3 gap-4 break-words font-gellix md:mb-21 md:grid"},_vm._l((_vm.productActive.specs),function(spec,specIndex){return _c('li',{key:specIndex,staticClass:"relative mb-3 flex flex-col border-b-2 pt-6 last:mb-10 last:border-b-0 md:mb-0 md:border-b-0 md:last:mb-0",class:{ logo: !!spec.image }},[(spec.image)?_c('ImageC',{staticClass:"mb-9",attrs:{"src":spec.image.filename,"alt":spec.image.alt}}):_vm._e(),_vm._v(" "),(spec.title)?_c('div',{staticClass:"mb-4 text-3xl font-bold leading-none md:text-3-5xl"},[_vm._v("\n            "+_vm._s(spec.title)+"\n          ")]):_vm._e(),_vm._v(" "),(spec.text)?_c('div',{staticClass:"mb-6 text-lg font-normal text-gray-700 md:mb-14 md:text-xl"},[_vm._v("\n            "+_vm._s(spec.text)+"\n          ")]):_vm._e()],1)}),0),_vm._v(" "),(_vm.productActive.button[0])?_c('LinkC',{staticClass:"font-regular block w-full transform cursor-pointer rounded-full border-2 border-orange-400 px-7 py-3 text-center font-firsneue text-sm text-orange-400 transition-all hover:bg-orange-400 hover:text-white lg:w-fit lg:text-lg",attrs:{"link":_vm.productActive.button[0].link}},[_vm._v("\n        "+_vm._s(_vm.productActive.button[0].text)+"\n      ")]):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }