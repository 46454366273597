











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ImageC extends Vue {
  @Prop() src!: string | null
  @Prop() alt!: string
  @Prop({ default: 0 }) height!: number
  @Prop({ default: 0 }) width!: number

  get altString(): string {
    if (this.alt) {
      return this.alt
    }

    return this.src?.split('/').slice(-1)[0].split('.')[0] || ''
  }

  get isSVG(): boolean {
    return this.src?.includes('.svg') || false
  }

  get srcSet() {
    if (this.isSVG || (this.height === 0 && this.width === 0)) {
      return undefined
    }

    return [
      this.getSrcResized(this.width, this.height),
      `${this.getSrcResized(this.width * 1.5, this.height * 1.5)} 1.5x`,
      `${this.getSrcResized(this.width * 2, this.height * 2)} 2x`,
      `${this.getSrcResized(640, 0)} 640w`,
      `${this.getSrcResized(768, 0)} 768w`,
      `${this.getSrcResized(1024, 0)} 1024w`,
      `${this.getSrcResized(1280, 0)} 1280w`,
      `${this.getSrcResized(1536, 0)} 1536w`,
    ].join(', ')
  }

  getSrcResized(width: number, height: number): string {
    if (!this.src || this.isSVG) {
      return this.src || ''
    }

    return `${this.src}/m/${width}x${height}`
  }
}
