var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onShortForm.apply(null, arguments)}}},[_c('div',{staticClass:"mb-10 px-6 lg:mb-21 lg:px-24"},[_c('div',{staticClass:"mb-21 text-lg leading-relaxed text-gray-900"},[_vm._v("\n      "+_vm._s(_vm.fields.text.label)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"mb-6 font-gellix text-lg font-bold leading-none text-gray-900 lg:mb-12 lg:text-3xl"},[_vm._v("\n      "+_vm._s(_vm.fields.subtitle.label)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 gap-8 lg:grid-cols-2"},[_c('InputSelect',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields.salutation.label,"options":_vm.fields.salutation.items,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.salutation)
            ? _vm.fields.salutation.error
            : ''},model:{value:(_vm.data.salutation),callback:function ($$v) {_vm.$set(_vm.data, "salutation", $$v)},expression:"data.salutation"}}),_vm._v(" "),_c('InputText',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields.firstName.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.firstName)
            ? _vm.fields.firstName.error
            : ''},model:{value:(_vm.data.firstName),callback:function ($$v) {_vm.$set(_vm.data, "firstName", $$v)},expression:"data.firstName"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields.lastName.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.lastName)
            ? _vm.fields.lastName.error
            : ''},model:{value:(_vm.data.lastName),callback:function ($$v) {_vm.$set(_vm.data, "lastName", $$v)},expression:"data.lastName"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields.company.label},model:{value:(_vm.data.company),callback:function ($$v) {_vm.$set(_vm.data, "company", $$v)},expression:"data.company"}}),_vm._v(" "),_c('InputText',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields.street.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.street)
            ? _vm.fields.street.error
            : ''},model:{value:(_vm.data.street),callback:function ($$v) {_vm.$set(_vm.data, "street", $$v)},expression:"data.street"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields['street-num'].label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data['street-num'])
            ? _vm.fields['street-num'].error
            : ''},model:{value:(_vm.data['street-num']),callback:function ($$v) {_vm.$set(_vm.data, 'street-num', $$v)},expression:"data['street-num']"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields.postcode.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.postcode)
            ? _vm.fields.postcode.error
            : ''},model:{value:(_vm.data.postcode),callback:function ($$v) {_vm.$set(_vm.data, "postcode", $$v)},expression:"data.postcode"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields.city.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.city)
            ? _vm.fields.city.error
            : ''},model:{value:(_vm.data.city),callback:function ($$v) {_vm.$set(_vm.data, "city", $$v)},expression:"data.city"}}),_vm._v(" "),_c('InputCountry',{attrs:{"label":_vm.fields.country.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.country)
            ? _vm.fields.topic.error
            : ''},model:{value:(_vm.data.country),callback:function ($$v) {_vm.$set(_vm.data, "country", $$v)},expression:"data.country"}}),_vm._v(" "),_c('InputText',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields.email.label,"error":_vm.isErrorMessageShown && !_vm.getIsEmailValid(_vm.data.email)
            ? _vm.fields.email.error
            : '',"type":"email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_vm._v(" "),_c('InputPhone',{attrs:{"label":_vm.fields.tel.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.tel)
            ? _vm.fields.tel.error
            : ''},model:{value:(_vm.data.tel),callback:function ($$v) {_vm.$set(_vm.data, "tel", $$v)},expression:"data.tel"}}),_vm._v(" "),_c('div',{staticClass:"mb-6 lg:col-span-2 lg:col-start-1 lg:mb-12"},[_c('InputTextarea',{attrs:{"label":_vm.fields.message.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.message)
              ? _vm.fields.message.error
              : ''},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}})],1),_vm._v(" "),_c('div',{staticClass:"mb-3 font-gellix text-lg font-bold leading-none text-gray-900 lg:mb-6 lg:text-3xl"},[_vm._v("\n        "+_vm._s(_vm.fields['project-title'].label)+"\n      ")]),_vm._v(" "),_c('InputRadioGroup',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields['contruction-project'].label,"options":_vm.fields['contruction-project'].items,"error":_vm.isErrorMessageShown &&
          !_vm.getIsRequiredValid(_vm.data['contruction-project'])
            ? _vm.fields['contruction-project'].error
            : ''},model:{value:(_vm.data['contruction-project']),callback:function ($$v) {_vm.$set(_vm.data, 'contruction-project', $$v)},expression:"data['contruction-project']"}}),_vm._v(" "),_c('InputSelect',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields['builder-type'].label,"options":_vm.fields['builder-type'].items,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data['builder-type'])
            ? _vm.fields['builder-type'].error
            : ''},model:{value:(_vm.data['builder-type']),callback:function ($$v) {_vm.$set(_vm.data, 'builder-type', $$v)},expression:"data['builder-type']"}}),_vm._v(" "),_c('InputSelect',{attrs:{"label":_vm.fields.floors.label,"options":_vm.fields.floors.items,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.floors)
            ? _vm.fields.floors.error
            : ''},model:{value:(_vm.data.floors),callback:function ($$v) {_vm.$set(_vm.data, "floors", $$v)},expression:"data.floors"}}),_vm._v(" "),_c('InputSelect',{attrs:{"label":_vm.fields['roof-orientation'].label,"options":_vm.fields['roof-orientation'].items,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data['roof-orientation'])
            ? _vm.fields['roof-orientation'].error
            : ''},model:{value:(_vm.data['roof-orientation']),callback:function ($$v) {_vm.$set(_vm.data, 'roof-orientation', $$v)},expression:"data['roof-orientation']"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields['roof-area'].label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data['roof-area'])
            ? _vm.fields['roof-area'].error
            : ''},model:{value:(_vm.data['roof-area']),callback:function ($$v) {_vm.$set(_vm.data, 'roof-area', $$v)},expression:"data['roof-area']"}}),_vm._v(" "),_c('InputSelect',{attrs:{"label":_vm.fields['roof-pitch'].label,"options":_vm.fields['roof-pitch'].items,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data['roof-pitch'])
            ? _vm.fields['roof-pitch'].error
            : ''},model:{value:(_vm.data['roof-pitch']),callback:function ($$v) {_vm.$set(_vm.data, 'roof-pitch', $$v)},expression:"data['roof-pitch']"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields['power-consumtion'].label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data['power-consumtion'])
            ? _vm.fields['power-consumtion'].error
            : ''},model:{value:(_vm.data['power-consumtion']),callback:function ($$v) {_vm.$set(_vm.data, 'power-consumtion', $$v)},expression:"data['power-consumtion']"}}),_vm._v(" "),_c('InputText',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields['desired-install-date'].label,"type":"date","error":_vm.isErrorMessageShown &&
          !_vm.getIsRequiredValid(_vm.data['desired-install-date'])
            ? _vm.fields['desired-install-date'].error
            : '',"placeholder":_vm.fields['desired-install-date'].placeholder},model:{value:(_vm.data['desired-install-date']),callback:function ($$v) {_vm.$set(_vm.data, 'desired-install-date', $$v)},expression:"data['desired-install-date']"}}),_vm._v(" "),_c('div',{staticClass:"mt-6 font-gellix text-lg font-bold leading-none text-gray-900 lg:col-start-1 lg:mt-12 lg:text-3xl"},[_vm._v("\n        "+_vm._s(_vm.fields['project-address-title'].label)+"\n      ")]),_vm._v(" "),_c('InputCheckbox',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields['project-address-checkbox'].label},model:{value:(_vm.data['project-address-checkbox']),callback:function ($$v) {_vm.$set(_vm.data, 'project-address-checkbox', $$v)},expression:"data['project-address-checkbox']"}}),_vm._v(" "),(!_vm.data['project-address-checkbox'])?_c('div',{staticClass:"col-span-1 col-start-1 grid grid-cols-2 gap-x-4 gap-y-8"},[_c('InputText',{staticClass:"col-span-2 lg:col-span-1",attrs:{"label":_vm.fields['project-address-street'].label,"error":_vm.isErrorMessageShown &&
            !_vm.data['project-address-checkbox'] &&
            !_vm.getIsRequiredValid(_vm.data['project-address-street'])
              ? _vm.fields['project-address-street'].error
              : ''},model:{value:(_vm.data['project-address-street']),callback:function ($$v) {_vm.$set(_vm.data, 'project-address-street', $$v)},expression:"data['project-address-street']"}}),_vm._v(" "),_c('InputText',{staticClass:"col-span-2 lg:col-span-1",attrs:{"label":_vm.fields['project-address-street-num'].label,"error":_vm.isErrorMessageShown &&
            !_vm.data['project-address-checkbox'] &&
            !_vm.getIsRequiredValid(_vm.data['project-address-street-num'])
              ? _vm.fields['project-address-street-num'].error
              : ''},model:{value:(_vm.data['project-address-street-num']),callback:function ($$v) {_vm.$set(_vm.data, 'project-address-street-num', $$v)},expression:"data['project-address-street-num']"}}),_vm._v(" "),_c('InputText',{staticClass:"col-span-2 lg:col-span-1",attrs:{"label":_vm.fields['project-address-postcode'].label,"error":_vm.isErrorMessageShown &&
            !_vm.data['project-address-checkbox'] &&
            !_vm.getIsRequiredValid(_vm.data['project-address-postcode'])
              ? _vm.fields['project-address-postcode'].error
              : ''},model:{value:(_vm.data['project-address-postcode']),callback:function ($$v) {_vm.$set(_vm.data, 'project-address-postcode', $$v)},expression:"data['project-address-postcode']"}}),_vm._v(" "),_c('InputText',{staticClass:"col-span-2 lg:col-span-1",attrs:{"label":_vm.fields['project-address-city'].label,"error":_vm.isErrorMessageShown &&
            !_vm.data['project-address-checkbox'] &&
            !_vm.getIsRequiredValid(_vm.data['project-address-city'])
              ? _vm.fields['project-address-city'].error
              : ''},model:{value:(_vm.data['project-address-city']),callback:function ($$v) {_vm.$set(_vm.data, 'project-address-city', $$v)},expression:"data['project-address-city']"}}),_vm._v(" "),_c('InputCountry',{staticClass:"col-span-2 lg:col-span-1",attrs:{"label":_vm.fields.country.label,"error":_vm.isErrorMessageShown &&
            !_vm.getIsRequiredValid(_vm.data['project-address-country'])
              ? _vm.fields.country.error
              : ''},model:{value:(_vm.data['project-address-country']),callback:function ($$v) {_vm.$set(_vm.data, 'project-address-country', $$v)},expression:"data['project-address-country']"}})],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"lg:py-18 rounded-b-xl bg-gray-200 px-6 py-14 lg:px-24"},[_c('InputCheckbox',{staticClass:"mb-8",attrs:{"label":_vm.fields.terms.label,"error":_vm.isErrorMessageShown && !_vm.data.terms ? _vm.fields.terms.error : ''},model:{value:(_vm.data.terms),callback:function ($$v) {_vm.$set(_vm.data, "terms", $$v)},expression:"data.terms"}}),_vm._v(" "),_c('button',{staticClass:"font-regular pointer-events-auto block w-full transform cursor-pointer rounded-full bg-orange-400 px-10 py-3 text-center font-firsneue text-lg text-white transition-all hover:bg-orange-500 lg:w-80",attrs:{"type":"submit"}},[_vm._v("\n      "+_vm._s(_vm.fields.button.label)+"\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }