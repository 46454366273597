var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onShortForm.apply(null, arguments)}}},[_c('div',{staticClass:"mb-10 px-6 lg:mb-21 lg:px-24"},[_c('div',{staticClass:"mb-21 text-lg leading-relaxed text-gray-900"},[_vm._v("\n      "+_vm._s(_vm.fields.text.label)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"mb-6 font-gellix text-lg font-bold leading-none text-gray-900 lg:mb-12 lg:text-3xl"},[_vm._v("\n      "+_vm._s(_vm.fields.subtitle.label)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 gap-8 lg:grid-cols-2"},[_c('InputSelect',{staticClass:"lg:col-start-1 lg:col-end-2",attrs:{"label":_vm.fields.salutation.label,"options":_vm.fields.salutation.items,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.salutation)
            ? _vm.fields.salutation.error
            : ''},model:{value:(_vm.data.salutation),callback:function ($$v) {_vm.$set(_vm.data, "salutation", $$v)},expression:"data.salutation"}}),_vm._v(" "),_c('InputText',{staticClass:"lg:col-start-1",attrs:{"label":_vm.fields.firstName.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.firstName)
            ? _vm.fields.firstName.error
            : ''},model:{value:(_vm.data.firstName),callback:function ($$v) {_vm.$set(_vm.data, "firstName", $$v)},expression:"data.firstName"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields.lastName.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.lastName)
            ? _vm.fields.lastName.error
            : ''},model:{value:(_vm.data.lastName),callback:function ($$v) {_vm.$set(_vm.data, "lastName", $$v)},expression:"data.lastName"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields.email.label,"error":_vm.isErrorMessageShown && !_vm.getIsEmailValid(_vm.data.email)
            ? _vm.fields.email.error
            : '',"type":"email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_vm._v(" "),_c('InputPhone',{attrs:{"label":_vm.fields.tel.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.tel)
            ? _vm.fields.tel.error
            : ''},model:{value:(_vm.data.tel),callback:function ($$v) {_vm.$set(_vm.data, "tel", $$v)},expression:"data.tel"}}),_vm._v(" "),_c('InputText',{attrs:{"label":_vm.fields.postcode.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.postcode)
            ? _vm.fields.postcode.error
            : ''},model:{value:(_vm.data.postcode),callback:function ($$v) {_vm.$set(_vm.data, "postcode", $$v)},expression:"data.postcode"}}),_vm._v(" "),_c('InputCountry',{attrs:{"label":_vm.fields.country.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.country)
            ? _vm.fields.topic.error
            : ''},model:{value:(_vm.data.country),callback:function ($$v) {_vm.$set(_vm.data, "country", $$v)},expression:"data.country"}}),_vm._v(" "),_c('InputSelect',{attrs:{"label":_vm.fields.topic.label,"options":_vm.fields.topic.items,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.topic)
            ? _vm.fields.topic.error
            : ''},model:{value:(_vm.data.topic),callback:function ($$v) {_vm.$set(_vm.data, "topic", $$v)},expression:"data.topic"}}),_vm._v(" "),_c('InputTextarea',{staticClass:"col-start-1 lg:col-span-2",attrs:{"label":_vm.fields.message.label,"error":_vm.isErrorMessageShown && !_vm.getIsRequiredValid(_vm.data.message)
            ? _vm.fields.message.error
            : ''},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}})],1)]),_vm._v(" "),_c('div',{staticClass:"lg:py-18 rounded-b-xl bg-gray-200 px-6 py-14 lg:px-24"},[_c('InputCheckbox',{staticClass:"mb-8",attrs:{"label":_vm.fields.terms.label,"error":_vm.isErrorMessageShown && !_vm.data.terms ? _vm.fields.terms.error : ''},model:{value:(_vm.data.terms),callback:function ($$v) {_vm.$set(_vm.data, "terms", $$v)},expression:"data.terms"}}),_vm._v(" "),_c('button',{staticClass:"font-regular pointer-events-auto block w-full transform cursor-pointer rounded-full bg-orange-400 px-10 py-3 text-center font-firsneue text-lg text-white transition-all hover:bg-orange-500 lg:w-80",attrs:{"type":"submit"}},[_vm._v("\n      "+_vm._s(_vm.fields.button.label)+"\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }