





















import { Vue, Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { StoryData } from 'storyblok-js-client'
import Header from '../components/Header.vue'
import {
  EVENT_TOGGLE_FORM_CONTACT,
  EventBus,
  EVENT_TOGGLE_GALLERY,
} from '../event-bus'

@Component({
  components: {
    Header,
  },
})
export default class LayoutDefault extends Vue {
  @Getter story!: StoryData | null
  @Getter locale!: string
  @Getter locales!: string[]

  @Mutation setStory!: (data: StoryData) => void
  @Mutation setLocale!: (val: string) => void

  isContactModalOpen: boolean = false
  isGalleryModalOpen: boolean = false
  formContactIndexOpen: number = 0
  galleryIndexOpen: number = 0

  // not using the locales from the store because ch-de will be de
  languageLocaleMap: { [languageKey: string]: string[] } = process.env
    .COUNTRIES_MAP as any

  get isHome(): boolean {
    return this.$route.params.slug === undefined
  }

  mounted() {
    EventBus.$on(EVENT_TOGGLE_FORM_CONTACT, this.toggleContactFormOpen)
    EventBus.$on(EVENT_TOGGLE_GALLERY, this.toggleGalleryFormOpen)

    if (process.env.VUE_IS_PREVIEW === 'true') {
      this.handleStoryblok()
    }

    if (process.env.VUE_IS_DETECT_LANGUAGE === 'true') {
      this.detectLanguage()
    }

    this.onRouteQuery()
  }

  destroyed() {
    EventBus.$off(EVENT_TOGGLE_FORM_CONTACT, this.toggleContactFormOpen)
    EventBus.$off(EVENT_TOGGLE_GALLERY, this.toggleGalleryFormOpen)
  }

  onRouteQuery() {
    const queryForm = this.$route.query.form

    if (queryForm) {
      if (queryForm === 'contact') {
        this.toggleContactFormOpen(0)
      }
      if (queryForm === 'quote') {
        this.toggleContactFormOpen(1)
      }
    }
  }

  toggleContactFormOpen(formContactIndexOpen: number) {
    this.isContactModalOpen = !this.isContactModalOpen
    this.formContactIndexOpen = formContactIndexOpen
  }

  toggleGalleryFormOpen(galleryIndexOpen: number) {
    this.isGalleryModalOpen = !this.isGalleryModalOpen
    this.galleryIndexOpen = galleryIndexOpen
  }

  onGalleryClose() {
    this.isGalleryModalOpen = false
    this.galleryIndexOpen = 0
  }

  get storedLanguageKey(): string {
    return `${window.location.host}_language`
  }

  get storedLanguage(): string {
    return localStorage.getItem(this.storedLanguageKey) || ''
  }

  setStoredLanguage(language: string) {
    localStorage.setItem(this.storedLanguageKey, language)
  }

  onLanguageSwitch() {
    // use the not current locale
    const locale = this.locales.find((l) => l !== this.locale)

    if (locale) {
      const language = this.getLanguageByLocale(locale)

      if (language) {
        this.setStoredLanguage(language)

        window.location.href = `/${locale}/`
      }
    }
  }

  getLanguageByLocale(locale: string) {
    let lang = ''

    // find lang depending on which website used
    Object.keys(this.languageLocaleMap).forEach((langKey) => {
      if (this.languageLocaleMap[langKey].includes(locale)) {
        lang = langKey
      }
    })

    return lang
  }

  getLocaleByLanguage(language: string) {
    // find lang depending on which website used
    return this.locales.find((l) =>
      this.languageLocaleMap[language].find((lang) => lang === l)
    )
  }

  detectLanguage() {
    // if locale is stored because of switching the language, do not redirect
    if (
      this.storedLanguage !== null &&
      Object.keys(this.languageLocaleMap).includes(this.storedLanguage)
    ) {
      const locale = this.getLocaleByLanguage(this.storedLanguage)

      // if locale is not current, redirect
      if (this.locale !== locale) {
        window.location.href = `/${locale}/${window.location.search}`
      }

      return
    }

    // if no language is stored, use browser to detect
    const foundLocale = Object.keys(this.languageLocaleMap).find((l) =>
      window.navigator.language.includes(l)
    )

    if (foundLocale) {
      const locale = this.getLocaleByLanguage(foundLocale)

      // if browser language is in locales and is not already set
      if (this.locale !== locale) {
        window.location.href = `/${locale}/${window.location.search}`
      }
    }
  }

  handleStoryblok() {
    ;((this as any).$storybridge as StoryblokBridge).on(
      ['input', 'published', 'change'],
      (payload?: StoryblokEventPayload) => {
        if (payload === undefined) {
          return
        }

        if (payload.action === 'input') {
          // Inject content on the input payload
          if (payload.story.id === this.story?.id) {
            this.setStory(payload.story)
          }
        } else if (payload.reload === true) {
          // Reload the page on save events
          window.location.reload()
        }
      }
    )
  }
}
