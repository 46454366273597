







































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { directive } from 'vue-awesome-swiper'
import tailwindConfig from '../../../tailwind.config.js'
import { ISettings } from '~/types'

import { EventBus, EVENT_TOGGLE_GALLERY } from '~/event-bus'

@Component({
  directives: {
    swiper: directive,
  },
})
export default class ProjectGallery extends Vue {
  @Prop() blok!: {
    head: {
      label: string
      title: string
    }[]
  }

  @Getter settings!: ISettings

  iconLeftColor: string = tailwindConfig.theme.colors.gray[500]
  iconRightColor: string = tailwindConfig.theme.colors.gray[500]
  orange: string = tailwindConfig.theme.colors.orange[400]
  gray: string = tailwindConfig.theme.colors.gray[500]
  activeProjectIndex: number = 0

  projectsSwiperOptions: object = {
    breakpoints: {
      0: {
        slidesPerView: 1.2,
      },
      1024: {
        slidesPerView: 2.7,
      },
    },
    spaceBetween: 32,
    navigation: {
      nextEl: '#carousel-swiper_next',
      prevEl: '#carousel-swiper_prev',
    },
  }

  handleClick(index: number) {
    EventBus.$emit(EVENT_TOGGLE_GALLERY, index)
  }
}
