








import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class PageError extends Vue {
  mounted() {
    // this.$router.push('/')
  }
}
