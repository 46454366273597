import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3accca06&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3accca06",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHead: require('/vercel/path0/components/SectionHead.vue').default,IconC: require('/vercel/path0/components/IconC.vue').default,ImageC: require('/vercel/path0/components/ImageC.vue').default})
