








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Textcontent extends Vue {
  @Prop() blok!: {}
}
