
























































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { directive } from 'vue-awesome-swiper'
import { IStoryAsset, IStoryLink } from '~/types'

type Product = {
  title: string
  subtitle: string
  variants: {
    title: string
    color: {
      // storyblok plugin
      color: string
    }
    images: IStoryAsset[]
  }[]
  specs: {
    title: string
    text: string
    image: IStoryAsset
  }[]
  button: {
    link: IStoryLink
    text: string
  }[]
}

@Component({
  directives: {
    swiper: directive,
  },
})
export default class ProductDetails extends Vue {
  @Prop() blok!: {
    head: {
      label: string
      title: string
    }[]
    products: Product[]
  }

  tabSwiperOption: object = {
    slidesPerView: 'auto',
    spaceBetween: 32,
  }

  productIndexActive: number = 0
  productVariantIndexActive: number = 0
  isMobile: boolean = false

  onProduct(index: number) {
    this.productIndexActive = index
    this.productVariantIndexActive = 0
  }

  onVariant(index: number) {
    this.productVariantIndexActive = index
  }

  get productActive() {
    return this.blok.products[this.productIndexActive]
  }

  get productVariantActive() {
    return this.productActive.variants[this.productVariantIndexActive]
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches
  }

  mounted() {
    window.addEventListener('resize', this.mobileCheck)
    this.mobileCheck()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
