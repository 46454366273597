






















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { IFormContactShortData, IFormField } from '~/types'

@Component({})
export default class FormContactShort extends Vue {
  @Prop() fields!: {
    text: IFormField
    subtitle: IFormField
    salutation: IFormField
    firstName: IFormField
    lastName: IFormField
    email: IFormField
    tel: IFormField
    topic: IFormField
    postcode: IFormField
    country: IFormField
    message: IFormField
    button: IFormField
    terms: IFormField
  }

  @Action formContactSendShort!: (p: {
    data: IFormContactShortData
  }) => Promise<void>

  isErrorMessageShown: boolean = false

  data: IFormContactShortData = {
    salutation: this.fields.salutation.items
      ? this.fields.salutation.items[0].id
      : '',
    firstName: '',
    lastName: '',
    email: '',
    topic: '',
    message: '',
    tel: '',
    country: '',
    postcode: '',
    terms: false,
    conference: this.$route.query.conference?.toString() || '',
  }

  getIsEmailValid(email: string): boolean {
    return /\S+@\S+\.\S+/.test(email)
  }

  getIsRequiredValid(val: string): boolean {
    return val.length > 0
  }

  get isValid(): boolean {
    return [
      this.getIsEmailValid(this.data.email),
      this.getIsRequiredValid(this.data.firstName),
      this.getIsRequiredValid(this.data.lastName),
      this.getIsRequiredValid(this.data.topic),
      this.getIsRequiredValid(this.data.message),
      this.getIsRequiredValid(this.data.tel),
      this.getIsRequiredValid(this.data.postcode),
      this.getIsRequiredValid(this.data.country),
      this.data.terms,
    ].every((v) => !!v)
  }

  async onShortForm() {
    this.isErrorMessageShown = false

    if (this.isValid === false) {
      this.isErrorMessageShown = true
      return
    }

    try {
      await this.formContactSendShort({ data: this.data })

      this.$emit('success')

      this.data = {
        salutation: this.fields.salutation.items
          ? this.fields.salutation.items[0].id
          : '',
        firstName: '',
        lastName: '',
        email: '',
        topic: '',
        message: '',
        tel: '',
        country: '',
        postcode: '',
        terms: false,
        conference: this.$route.query.conference?.toString() || '',
      }
    } catch (e) {
      alert(e)
    }
  }
}
