var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-28 md:mb-56"},[(_vm.blok.is_compact)?_c('div',{staticClass:"px-4 font-gellix md:px-10 lg:px-28 xl:mx-auto xl:max-w-screen-xl xl:px-4"},[_c('div',{staticClass:"mb-6 font-firsneue text-3xl font-semibold lg:mb-14 lg:text-5xl"},[_vm._v("\n      "+_vm._s(_vm.blok.title)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isMobile)?_c('div',{directives:[{name:"swiper",rawName:"v-swiper:tabSwiper",value:(_vm.tabSwiperOption),expression:"tabSwiperOption",arg:"tabSwiper"}]},[(!_vm.blok.is_compact)?_c('div',{staticClass:"mb-4 px-6 font-firsneue text-lg font-semibold"},[_vm._v("\n      "+_vm._s(_vm.blok.title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"swiper-wrapper mb-12 px-6"},_vm._l((_vm.blok.tabs),function(tab,index){return _c('li',{key:index,staticClass:"swiper-slide w-auto cursor-pointer font-firsneue text-lg last:mr-8",class:index === _vm.tabIndexActive
            ? 'font-demibold text-orange-400 underline underline-offset-8'
            : 'font-normal text-gray-500',on:{"click":function($event){return _vm.onTab(index)}}},[_vm._v("\n        "+_vm._s(tab.title)+"\n      ")])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"px-6 font-gellix md:px-10 lg:px-28 xl:mx-auto xl:max-w-screen-xl xl:px-4"},[_c('div',{staticClass:"hidden md:block"},[_c('ul',{staticClass:"mb-16 ml-0"},[(!_vm.blok.is_compact)?_c('li',{staticClass:"mr-12 hidden font-firsneue text-2xl font-semibold xl:inline-block"},[_vm._v("\n          "+_vm._s(_vm.blok.title)+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.blok.tabs),function(tab,index){return _c('li',{key:index,staticClass:"mr-12 mb-4 inline-block cursor-pointer text-2xl hover:text-orange-400",class:index === _vm.tabIndexActive
              ? 'font-firsneue font-semibold text-orange-400 underline underline-offset-8'
              : 'font-normal text-gray-500',on:{"click":function($event){return _vm.onTab(index)}}},[_vm._v("\n          "+_vm._s(tab.title)+"\n        ")])})],2)]),_vm._v(" "),_c('div',{key:_vm.tabIndexActive,staticClass:"mb-14 md:mb-16"},[(_vm.blok.is_compact)?_c('div',{staticClass:"mb-4 font-gellix text-2xl font-bold text-gray-900 lg:mb-8 lg:text-3xl"},[_vm._v("\n        "+_vm._s(_vm.tabActive.title)+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.tabActive.accordion),function(item,index){return _c('div',{key:index,ref:"item",refInFor:true,staticClass:"cursor-pointer",class:_vm.blok.is_compact ? '' : ' mb-6 last:mb-0 lg:mb-12',on:{"click":function($event){return _vm.onAccordion(index)}}},[_c('div',{staticClass:"border-b border-gray-500",class:_vm.blok.is_compact ? ' py-6 lg:py-12' : 'pb-8 lg:pb-14'},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"pr-6 font-gellix leading-snug text-gray-700 md:pr-32",class:[
                _vm.blok.is_compact
                  ? 'text-base lg:text-lg'
                  : 'text-2xl lg:text-5xl',
                _vm.blok.is_compact && index === _vm.accordionIndexActive
                  ? 'font-semibold'
                  : '' ]},[_vm._v("\n              "+_vm._s(item.title)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"relative flex flex-shrink-0 flex-col items-center justify-around",class:_vm.blok.is_compact ? 'h-6 w-6' : 'h-10 w-10 lg:h-16 lg:w-16'},[_c('span',{staticClass:"block w-full transform bg-orange-400 transition-all duration-300",class:[
                  _vm.blok.is_compact ? 'h-0.5' : 'h-[3px] md:h-[5px]',
                  index === _vm.accordionIndexActive ? 'scale-x-0' : 'scale-x-1' ]}),_vm._v(" "),_c('span',{staticClass:"absolute block w-full bg-orange-400 transition delay-100 duration-300 ease-in-out",class:[
                  index === _vm.accordionIndexActive ? 'rotate-0' : '-rotate-90',
                  _vm.blok.is_compact ? 'h-0.5' : 'h-[3px] md:h-[5px]' ]})])]),_vm._v(" "),_c('div',{ref:"text",refInFor:true,staticClass:"h-0 origin-top opacity-0",class:index === _vm.accordionIndexActive ? '' : 'pointer-events-none'},[_c('TextC',{staticClass:"pr-6 last:mb-0 md:pr-32",attrs:{"text":item.text}})],1)])])})],2),_vm._v(" "),(_vm.blok.button && !_vm.blok.is_compact)?_c('div',_vm._l((_vm.blok.button),function(button,i){return _c('LinkC',{key:i,staticClass:"font-regular block w-full transform cursor-pointer rounded-full border-2 border-orange-400 px-7 py-3 text-center font-firsneue text-sm text-orange-400 transition-all hover:bg-orange-400 hover:text-white lg:w-fit lg:text-lg",attrs:{"link":button.link}},[_vm._v("\n        "+_vm._s(button.text)+"\n      ")])}),1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }