















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { directive } from 'vue-awesome-swiper'
import { IStoryAsset } from '~/types'

@Component({
  directives: {
    swiper: directive,
  },
})
export default class TextContentImageItem extends Vue {
  @Prop() body!: {
    title: string
    subtitle: string
    image: IStoryAsset
  }[]

  isMobile: boolean = false

  imageSwiperOption: object = {
    slidesPerView: 1.5,
    spaceBetween: 16,
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches
  }

  mounted() {
    window.addEventListener('resize', this.mobileCheck)
    this.mobileCheck()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.mobileCheck)
  }
}
