export const ButtonContact = () => import('../../components/ButtonContact.vue' /* webpackChunkName: "components/button-contact" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormContact = () => import('../../components/FormContact.vue' /* webpackChunkName: "components/form-contact" */).then(c => wrapFunctional(c.default || c))
export const FormContactLong = () => import('../../components/FormContactLong.vue' /* webpackChunkName: "components/form-contact-long" */).then(c => wrapFunctional(c.default || c))
export const FormContactShort = () => import('../../components/FormContactShort.vue' /* webpackChunkName: "components/form-contact-short" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const IconC = () => import('../../components/IconC.vue' /* webpackChunkName: "components/icon-c" */).then(c => wrapFunctional(c.default || c))
export const ImageC = () => import('../../components/ImageC.vue' /* webpackChunkName: "components/image-c" */).then(c => wrapFunctional(c.default || c))
export const InputCheckbox = () => import('../../components/InputCheckbox.vue' /* webpackChunkName: "components/input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputCountry = () => import('../../components/InputCountry.vue' /* webpackChunkName: "components/input-country" */).then(c => wrapFunctional(c.default || c))
export const InputPhone = () => import('../../components/InputPhone.vue' /* webpackChunkName: "components/input-phone" */).then(c => wrapFunctional(c.default || c))
export const InputRadioGroup = () => import('../../components/InputRadioGroup.vue' /* webpackChunkName: "components/input-radio-group" */).then(c => wrapFunctional(c.default || c))
export const InputSelect = () => import('../../components/InputSelect.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/InputText.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const InputTextarea = () => import('../../components/InputTextarea.vue' /* webpackChunkName: "components/input-textarea" */).then(c => wrapFunctional(c.default || c))
export const LinkC = () => import('../../components/LinkC.vue' /* webpackChunkName: "components/link-c" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const SectionHead = () => import('../../components/SectionHead.vue' /* webpackChunkName: "components/section-head" */).then(c => wrapFunctional(c.default || c))
export const TextC = () => import('../../components/TextC.vue' /* webpackChunkName: "components/text-c" */).then(c => wrapFunctional(c.default || c))
export const TextContentImageGrid = () => import('../../components/TextContentImageGrid.vue' /* webpackChunkName: "components/text-content-image-grid" */).then(c => wrapFunctional(c.default || c))
export const BloksBanner = () => import('../../components/bloks/banner/index.vue' /* webpackChunkName: "components/bloks-banner" */).then(c => wrapFunctional(c.default || c))
export const BloksBigImage = () => import('../../components/bloks/big-image/index.vue' /* webpackChunkName: "components/bloks-big-image" */).then(c => wrapFunctional(c.default || c))
export const BloksCarousel = () => import('../../components/bloks/carousel/index.vue' /* webpackChunkName: "components/bloks-carousel" */).then(c => wrapFunctional(c.default || c))
export const BloksFacts = () => import('../../components/bloks/facts/index.vue' /* webpackChunkName: "components/bloks-facts" */).then(c => wrapFunctional(c.default || c))
export const BloksFaq = () => import('../../components/bloks/faq/index.vue' /* webpackChunkName: "components/bloks-faq" */).then(c => wrapFunctional(c.default || c))
export const BloksIntro = () => import('../../components/bloks/intro/index.vue' /* webpackChunkName: "components/bloks-intro" */).then(c => wrapFunctional(c.default || c))
export const BloksProductDetails = () => import('../../components/bloks/product-details/index.vue' /* webpackChunkName: "components/bloks-product-details" */).then(c => wrapFunctional(c.default || c))
export const BloksProjectGallery = () => import('../../components/bloks/project-gallery/index.vue' /* webpackChunkName: "components/bloks-project-gallery" */).then(c => wrapFunctional(c.default || c))
export const BloksScrollanimation = () => import('../../components/bloks/scrollanimation/index.vue' /* webpackChunkName: "components/bloks-scrollanimation" */).then(c => wrapFunctional(c.default || c))
export const BloksSteps = () => import('../../components/bloks/steps/index.vue' /* webpackChunkName: "components/bloks-steps" */).then(c => wrapFunctional(c.default || c))
export const BloksTextcontent = () => import('../../components/bloks/textcontent/index.vue' /* webpackChunkName: "components/bloks-textcontent" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
