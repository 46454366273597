






















import { Vue, Component, Prop } from 'vue-property-decorator'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

@Component({})
export default class SectionHead extends Vue {
  @Prop() blok!: {
    label: string
    number: string
    _uid: string
    title: string
  }
}
