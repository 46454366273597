


























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { directive } from 'vue-awesome-swiper'
import { IStoryAsset, IStoryLink } from '~/types'

@Component({
  directives: {
    swiper: directive,
  },
})
export default class Carousel extends Vue {
  @Prop() blok!: {
    _uid: string
    has_margin_bottom: boolean
    items: {
      title: string
      logo: IStoryAsset
      link: IStoryLink
    }[]
  }

  swiperOption: object = {
    centeredSlides: true,
    spaceBetween: 20,
    loop: this.blok.items.length > 1,
    allowTouchMove: this.blok.items.length > 1,
    slidesPerView: 1,
    pagination:
      this.blok.items.length > 1
        ? {
            el: `#${this.blok._uid}carousel-swiper_pagination`,
            clickable: true,
          }
        : false,
    navigation:
      this.blok.items.length > 1
        ? {
            nextEl: `#${this.blok._uid}carousel-swiper_next`,
            prevEl: `#${this.blok._uid}carousel-swiper_prev`,
          }
        : false,
  }
}
