import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=498cabf4&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconC: require('/vercel/path0/components/IconC.vue').default})
