





















import { Vue, Component, Prop } from 'vue-property-decorator'
import { IStoryLink } from '~/types'

@Component({})
export default class LinkC extends Vue {
  @Prop() link!: IStoryLink | undefined

  get url(): string {
    if (this.isSectionQuery) {
      return `${this.$route.fullPath}${this.link?.cached_url}`
    }

    return this.link?.cached_url || ''
  }

  get isExternal(): boolean {
    return !this.isSectionQuery && !!this.link && this.link.linktype === 'url'
  }

  get isSectionQuery(): boolean {
    const url = this.link?.cached_url

    return !!url?.includes('?section') || !!url?.includes('?contact')
  }
}
