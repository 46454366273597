




















import { Vue, Component, Watch } from 'vue-property-decorator'
import { gsap } from 'gsap'
import { EventBus, ANIMATION_SECTION_READY } from '../event-bus'

@Component({})
export default class Loader extends Vue {
  $refs!: {
    loader: HTMLElement
    spinner: HTMLElement
  }

  timeline: GSAPTimeline = gsap.timeline()

  get isHome(): boolean {
    return this.$route.params.slug === undefined
  }

  startLoader() {
    document.documentElement.style.overflow = 'hidden'
  }

  hideLoader() {
    document.documentElement.style.overflow = ''
    this.timeline = gsap
      .timeline()
      .fromTo(
        this.$refs.spinner,
        { scale: 1, opacity: 1 },
        { opacity: 0, duration: 0.3 }
      )
      .fromTo(
        this.$refs.loader,
        { scale: 1, opacity: 1 },
        { opacity: 0, duration: 1 },
        '<'
      )
  }

  handleLoader() {
    if (this.isHome) {
      this.startLoader()
      EventBus.$on(ANIMATION_SECTION_READY, () => {
        this.hideLoader()
      })
    }
  }

  mounted() {
    this.handleLoader()
  }

  @Watch('$route', { deep: true })
  onUrlChange() {
    this.handleLoader()
  }
}
