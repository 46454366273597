import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8797bd7c&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=8797bd7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8797bd7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageC: require('/vercel/path0/components/ImageC.vue').default,LinkC: require('/vercel/path0/components/LinkC.vue').default,IconC: require('/vercel/path0/components/IconC.vue').default})
