

















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import countriesJson from '../assets/json/countries-phone.en.json'

interface Country {
  name: string
  dial_code: string
  code: string
}

@Component({})
export default class InputPhone extends Vue {
  @Prop() label!: string
  @Prop() error!: string

  @Getter geoDetected!: string

  inputActive: boolean = false

  get options(): { label: string; id: string }[] {
    return this.countries.map((c) => ({
      id: c.dial_code,
      label: `${c.name} ${c.dial_code}`,
    }))
  }

  get countries(): Country[] {
    return [
      ...this.countriesTop.map(
        (code) =>
          countriesJson.find((c) => c.code === code) || {
            code: '',
            dial_code: '',
            name: '',
          }
      ),
      ...countriesJson.filter((c) => !this.countriesTop.includes(c.code)),
    ]
  }

  countriesTop: string[] = (process.env.COUNTRIES_TOP as any) || []
  countryDialCode: string = ''

  created() {
    this.countryDialCode =
      this.countries.find((o) => o.code === this.geoDetected)?.dial_code ||
      this.options[0].id
  }

  phoneNum: string = ''

  onInput() {
    const val = this.phoneNum ? this.countryDialCode + this.phoneNum : ''
    this.$emit('input', val)
  }
}
