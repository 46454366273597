




























































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import ButtonContact from './ButtonContact.vue'
import { ISettings, IStoryLink } from '~/types'
import { EventBus, EVENT_TOGGLE_FORM_CONTACT } from '~/event-bus'

gsap.registerPlugin(ScrollTrigger)

@Component({
  components: {
    ButtonContact,
  },
})
export default class Header extends Vue {
  @Getter settings!: ISettings
  @Getter locale!: string

  activeSideMenuIndex = 0
  isMenuActive = false
  width = 0
  isMobile: boolean = false
  desktopTimeline: GSAPTimeline = gsap.timeline()
  mobileTimeline: GSAPTimeline = gsap.timeline()

  $refs!: {
    imagemark: SVGPathElement
    header: HTMLElement
    textmark: SVGPathElement
    subline: SVGPathElement
    logo: HTMLElement
    desktopMenu: HTMLElement
    menuLink: HTMLElement[]
    scrollDistance: HTMLElement
    footerLinks: HTMLElement
    button: HTMLElement
    mobileButton: HTMLElement
    headerLinks: HTMLElement
    slideMenu: HTMLElement
    menuBackground: HTMLElement
    menuBorder: HTMLElement
    headerTop: HTMLElement
  }

  get isHome(): boolean {
    return this.$route.params.slug === undefined
  }

  get hasHash(): boolean {
    return this.$route.hash !== ''
  }

  get homeLink(): IStoryLink {
    return {
      id: '',
      url: '',
      linktype: '',
      fieldtype: '',
      cached_url: `/${this.locale}/`,
    }
  }

  onLink(link: IStoryLink, index: number) {
    this.activeSideMenuIndex = index
    if (this.isMobile) {
      this.toggleMenu(false)
    }

    if (this.$route.params.slug) {
      this.$router.push(`/${this.locale}/`)

      setTimeout(() => {
        this.scrollToSection(link, 'auto')
      }, 300)
    } else {
      this.scrollToSection(link)

      setTimeout(() => {
        this.scrollToSection(link)
      }, 500)
    }
  }

  scrollToTop() {
    if (this.isMobile) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  scrollToSection(link: IStoryLink, behavior: ScrollBehavior = 'smooth') {
    const $el = document.querySelector(link.cached_url)

    if ($el instanceof HTMLElement) {
      const OFFSET_TOP = 140
      window.scrollTo({
        top: $el.offsetTop - OFFSET_TOP,
        behavior,
      })
    }
  }

  toggleMenu(isActive: boolean = !this.isMenuActive) {
    this.isMenuActive = isActive
    const mobileMenu = gsap.timeline()
    gsap.defaults({ duration: 0.5 })

    mobileMenu
      .set(this.$refs.slideMenu, { display: 'flex' })
      .set(this.$refs.menuBorder, { display: 'flex' })

    if (this.isMenuActive) {
      mobileMenu
        .to(this.$refs.menuBackground, { opacity: 0.7 })
        .fromTo(this.$refs.menuBorder, { xPercent: -100 }, { xPercent: 0 }, '<')
        .fromTo(
          this.$refs.slideMenu,
          { xPercent: -100 },
          { xPercent: 0 },
          '<5%'
        )
        .fromTo(
          this.$refs.headerLinks,
          { x: -100 },
          { x: 0, stagger: 0.05 },
          '<20%'
        )
        .fromTo(
          this.$refs.footerLinks,
          { x: -100 },
          { x: 0, stagger: 0.025 },
          '<20%'
        )
        .fromTo(this.$refs.button, { x: -100 }, { x: 0 }, '<')
    } else {
      mobileMenu
        .to(this.$refs.menuBackground, { opacity: 0 })
        .fromTo(this.$refs.slideMenu, { xPercent: 0 }, { xPercent: -100 }, '<')
        .fromTo(
          this.$refs.menuBorder,
          { xPercent: 0 },
          { xPercent: -100 },
          '<5%'
        )
        .fromTo(this.$refs.headerLinks, { x: 0 }, { x: -100 })
        .fromTo(this.$refs.button, { x: 0 }, { x: -100 }, '<')
        .fromTo(this.$refs.footerLinks, { x: 0 }, { x: -100 }, '<')
    }

    if (this.isMenuActive) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }

  onContactOpen() {
    this.toggleMenu(false)
    EventBus.$emit(EVENT_TOGGLE_FORM_CONTACT, 0)
  }

  onOfferOpen() {
    this.toggleMenu(false)
    EventBus.$emit(EVENT_TOGGLE_FORM_CONTACT, 1)
  }

  animateMe() {
    ScrollTrigger.config({
      ignoreMobileResize: true,
    })
    if (!this.isMobile && this.isHome) {
      this.desktopTimeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.scrollDistance,
            scrub: 0.6,
            toggleActions: 'play none none reverse',
            start: '95% top',
            end: '+=300',
          },
        })
        .fromTo(
          this.$refs.headerTop,
          { background: 'transparent' },
          { background: 'white', y: -36, ease: 'Expo.easeOut' }
        )
        .to(
          this.$refs.textmark,
          { opacity: 0, y: -20, ease: 'Expo.easeOut' },
          '<'
        )
        .to(
          this.$refs.subline,
          { opacity: 0, y: -20, ease: 'Expo.easeOut' },
          '<'
        )
        .to(
          this.$refs.desktopMenu,
          { opacity: 1, y: 0, ease: 'Expo.easeOut', duration: 0.0001 },
          '<'
        )
        .fromTo(
          this.$refs.menuLink,
          { y: 20, opacity: 0 },
          { opacity: 1, y: 0, ease: 'Expo.easeOut' },
          '<'
        )
    }
    if (this.isMobile) {
      let triggerElement = this.$refs.header
      if (this.isHome) {
        triggerElement = this.$refs.scrollDistance
      }
      this.mobileTimeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: triggerElement,
            invalidateOnRefresh: true,
            toggleActions: 'play none none reverse',
            start: '95% top',
          },
        })
        .fromTo(
          this.$refs.logo,
          { opacity: 1, y: 0 },
          { opacity: 0, y: -36, duration: 0.15 }
        )
        .fromTo(
          this.$refs.mobileButton,
          { opacity: 0, y: 36 },
          { opacity: 1, y: 0, duration: 0.15 }
        )
    }
  }

  resetAnimations() {
    this.desktopTimeline.restart()
    this.desktopTimeline.kill()
    this.mobileTimeline.restart()
    this.mobileTimeline.kill()
    gsap.set(this.$refs.desktopMenu, { clearProps: 'all' })
    gsap.set(this.$refs.logo, { clearProps: 'all' })
    gsap.set(this.$refs.headerTop, { clearProps: 'all' })
    gsap.set(this.$refs.menuLink, { clearProps: 'all' })
  }

  mobileCheck() {
    this.isMobile = window.matchMedia('(max-width: 1023px)').matches
  }

  mounted() {
    this.mobileCheck()
    this.animateMe()
    if (this.isHome) {
      window.addEventListener('scroll', this.handleScroll)
    }

    this.width = window.innerWidth
    window.addEventListener('resize', () => {
      this.mobileCheck()
      if (window.innerWidth !== this.width) {
        this.width = window.innerWidth
        this.resetAnimations()
        this.animateMe()
      }
    })
  }

  get headerOffset(): number {
    return this.$refs.headerTop.clientHeight + 50
  }

  handleScroll() {
    this.settings.header_links.forEach((item, index) => {
      const $section = document.getElementById(
        item.link.cached_url.substring(1)
      )
      if (
        $section &&
        window.scrollY >= $section.offsetTop - this.headerOffset
      ) {
        this.activeSideMenuIndex = index
      }
    })
  }

  updated() {}

  @Watch('$route', { deep: true })
  onUrlChange() {
    this.resetAnimations()
    this.animateMe()
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.mobileCheck)
  }
}
